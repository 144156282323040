import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import InputFactory from "../../../../components/InputFactory";
import { findObjectUseCase } from "../../../../usecases/object";

export default function ReceiveInvoiceModal({
  isOpen,
  toggleModal,
  onClickSave,
  obj,
  withhold,
  onChange,
  onChangeAccount,
  onChangePayment,
  change,
}) {
  const [object, setObject] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [methodOptions, setMethodOptions] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const fetchData = async () => {
    try {
      const invoice = await findObjectUseCase().execute("invoices_final", {
        where: { id: obj },
      });
      const object = await findObjectUseCase().execute("invoices");
      const method = await findObjectUseCase().execute("paymode");
      const accounts = await findObjectUseCase().execute("gmd_accounts");

      setObject(object || []);
      setInvoice(invoice || []);
      setMethodOptions(Array.isArray(method) ? method : []);
      setAccounts(Array.isArray(accounts) ? accounts : []);
      console.log("accounts???", accounts);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
    }).format(amount);
  };

  //   console.log(
  //     "amounts",
  //     Number(change?.amount?.replace(/,/g, "")),
  //     invoice[0]?.collectibles
  //   );

  return (
    <Modal isOpen={isOpen} size="lg">
      <div className="p-4 d-flex flex-column gap-2">
        <div>
          <div className="text-start">
            <p
              className="fw-bold"
              style={{ color: "#0057B8", fontSize: "22px" }}
            >
              Receive Payment
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                className="text-center"
                style={{
                  border: "2px solid #EBBD2F",
                  borderRadius: "5px",
                  flex: 1,
                }}
              >
                <p className="m-2" style={{ fontSize: "16px" }}>
                  {/* {formatCurrency(invoice[0]?.collectibles)} */}
                  {/* {formatCurrency(invoice[0]?.collectibles - invoice[0]?.tax)} */}
                  {/* {change?.withholdTax === true
                    ? formatCurrency(
                        invoice[0]?.collectibles -
                          ((invoice[0]?.collectibles - invoice[0]?.tax) *
                            change?.withheldPercent) /
                            100
                      )
                    : formatCurrency(invoice[0]?.collectibles)} */}
                  {
                    // change?.withholdTax === true
                    //   ? formatCurrency(
                    //       invoice[0]?.collectibles -
                    //         ((invoice[0]?.collectibles - invoice[0]?.tax) *
                    //           change?.withheldPercent) /
                    //           100
                    //     )
                    //   :
                    formatCurrency(invoice[0]?.collectibles)
                  }
                </p>

                <p
                  className="m-0"
                  style={{
                    color: "#434343",
                    fontSize: "12px",
                    paddingBottom: "10px",
                  }}
                >
                  Remaining Balance
                </p>
              </div>
              {/* <div
                className="text-center"
                style={{
                  border: "2px solid #EBBD2F",
                  borderRadius: "5px",
                  flex: 1,
                }}
              >
                <p className="m-2" style={{ fontSize: "16px" }}>
                  {formatCurrency(invoice[0]?.tax)}
                </p>

                <p
                  className="m-0"
                  style={{
                    color: "#434343",
                    fontSize: "12px",
                    paddingBottom: "10px",
                  }}
                >
                  VAT
                </p>
              </div> */}
              {/* <div
                className="text-center"
                style={{
                  border: "2px solid #EBBD2F",
                  borderRadius: "5px",
                  flex: 1,
                }}
              >
                <p className="m-2" style={{ fontSize: "16px" }}>
                  {formatCurrency(invoice[0]?.tax)}
                </p>

                <p
                  className="m-0"
                  style={{
                    color: "#434343",
                    fontSize: "12px",
                    paddingBottom: "10px",
                  }}
                >
                  Tax in Balance
                </p>
              </div> */}
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  gap: "40px",
                  marginTop: "25px",
                }}
              >
                <div style={{ flex: "1" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      display: "block",
                    }}
                  >
                    Payment Method
                  </label>
                  <select
                    onChange={onChangePayment}
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      padding: "7px",
                      paddingRight: "15px",
                      marginTop: "6px",
                    }}
                  >
                    <option disabled selected hidden>
                      Select Method
                    </option>
                    {methodOptions.length === 0 ? (
                      <option disabled>No methods available</option>
                    ) : (
                      methodOptions.map((meth) => (
                        <option key={meth.id} value={meth.id}>
                          {meth.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div style={{ flex: "1" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      display: "block",
                    }}
                  >
                    Account
                  </label>
                  <select
                    onChange={onChangeAccount}
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      padding: "7px",
                      marginTop: "6px",
                    }}
                  >
                    <option disabled selected hidden>
                      Select an account
                    </option>
                    {accounts.length > 0 ? (
                      accounts.map((account) => {
                        if (
                          //   account.name === "Management money" ||
                          //   account.name === "GMD Revolving Fund" ||
                          //   account.name === "Metrobank" ||
                          //   account.name === "GMD Fund"
                          account.name == "Management money" ||
                          account.name == "GMD Revolving Fund" ||
                          // account.name == "Metrobank" ||
                          account.name == "GMD Fund"
                        ) {
                          return null;
                        } else {
                          return (
                            <option key={account.id} value={account.id}>
                              {account.name}
                            </option>
                          );
                        }
                      })
                    ) : (
                      <option disabled>No accounts available</option>
                    )}
                  </select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "40px",
                  marginTop: "20px",
                }}
              >
                <div style={{ flex: "1" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      display: "block",
                    }}
                  >
                    Amount
                  </label>
                  <InputFactory
                    type="parseNumber"
                    dynamic={false}
                    field="amount"
                    object={object}
                    placeholder="Enter Amount"
                    onChange={onChange}
                    required={true}
                  />
                </div>
                <div style={{ flex: "1" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      display: "block",
                    }}
                  >
                    Reference Number
                  </label>
                  <InputFactory
                    type="Number"
                    dynamic={false}
                    field="reference_number"
                    object={object}
                    placeholder="Enter Reference No."
                    onChange={onChange}
                  />
                </div>
              </div>

              <div style={{ marginTop: "20px" }}>
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    display: "block",
                  }}
                >
                  Date
                </label>
                <InputFactory
                  type="Date"
                  field="date"
                  placeholder="Add Date"
                  onChange={onChange}
                  required={true}
                />
              </div>

              {/* {withhold === true && ( */}
              {/* <div style={{ flex: "1", marginTop: "20px" }}> */}
              <div
                style={{ flex: "1", marginTop: "20px", position: "relative" }}
              >
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    display: "block",
                  }}
                >
                  Withhold Tax?
                </label>
                {/* <InputFactory
                  type="Boolean"
                  dynamic={false}
                  field="withholdTax"
                  object={object}
                  onChange={onChange}
                /> */}
                <div style={{ position: "relative", display: "inline-block" }}>
                  <InputFactory
                    type="Boolean"
                    dynamic={false}
                    field="withholdTax"
                    object={object}
                    onChange={onChange}
                    disabled={invoice[0]?.isWithHold === true}
                    style={{
                      cursor: invoice[0]?.isWithHold
                        ? "not-allowed"
                        : "pointer",
                    }}
                  />
                  {invoice[0]?.isWithHold && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "0",
                        backgroundColor: "#f8d7da",
                        color: "#721c24",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        marginTop: "1px",
                      }}
                    >
                      Withholding tax already applied
                    </div>
                  )}
                </div>
              </div>

              {change?.withholdTax === true && (
                <div
                  style={{
                    display: "flex",
                    gap: "40px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ flex: "1" }}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        display: "block",
                      }}
                    >
                      Withholding Tax
                    </label>
                    <InputFactory
                      type="Number"
                      dynamic={false}
                      field="withheldPercent"
                      object={object}
                      placeholder="Enter Tax Percent"
                      onChange={onChange}
                      required={true}
                    />
                  </div>
                  <div style={{ flex: "1" }}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        display: "block",
                      }}
                    >
                      {/* Remaining Balance - Withheld Tax */}
                      {/* New Balance */}
                      {/* New Balance + VAT */}
                      {/* New Balance (No VAT) */}
                      {/* New Balance (No VAT) */}
                      Total amount to be paid
                    </label>
                    <InputFactory
                      type="parseNumber"
                      dynamic={false}
                      field="newAmount"
                      object={object}
                      disabled
                      value={formatCurrency(
                        isNaN(invoice[0]?.collectibles)
                          ? 0
                          : change?.withheldPercent
                          ? invoice[0]?.collectibles -
                            // (invoice[0]?.tax * change?.withheldPercent) / 100
                            // (invoice[0]?.collectibles *
                            // ((invoice[0]?.collectibles - invoice[0]?.tax) *
                            // invoice[0]?.downpaymentValTax -
                            // ((invoice[0]?.collectibles -
                            //   invoice[0]?.downpaymentValTax) *
                            // invoice[0]?.tax -
                            // invoice[0]?.tax -

                            ((invoice[0]?.collectibles - invoice[0]?.tax) *
                              change?.withheldPercent) /
                              100
                          : //   : invoice[0]?.collectibles
                            // invoice[0]?.collectibles - invoice[0]?.tax
                            // invoice[0]?.collectibles -
                            // invoice[0]?.downpaymentValTax
                            // invoice[0]?.collectibles - invoice[0]?.tax
                            invoice[0]?.collectibles
                        //  - invoice[0]?.tax
                      )}
                      onChange={onChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex gap-4 "></div>
        <div className="col d-flex gap-2 w-100 justify-content-end">
          <button
            className="btn"
            style={{ background: "#e6e6e6" }}
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            className="btn"
            style={{ background: "#DAB44E" }}
            // onClick={() =>
            //   onClickSave(
            //     0,
            //     object,
            //     accounts,
            //     obj,
            //     withhold,
            //     invoice[0]?.collectibles -
            //       //   (invoice[0]?.tax * change?.withheldPercent) / 100
            //       //   (invoice[0]?.collectibles * change?.withheldPercent) / 100
            //       ((invoice[0]?.collectibles - invoice[0]?.tax) *
            //         change?.withheldPercent) /
            //         100
            //   )
            // }
            // onClick={() =>
            //   onClickSave(
            //     0,
            //     object,
            //     accounts,
            //     obj,
            //     withhold,
            // invoice[0]?.collectibles -
            //   invoice[0]?.downpaymentValTax -
            //   ((invoice[0]?.collectibles - invoice[0]?.downpaymentValTax) *
            //     change?.withheldPercent) /
            //     100
            //     // invoice[0]?.collectibles -
            //     //   ((invoice[0]?.collectibles - invoice[0]?.tax) *
            //     //     change?.withheldPercent) /
            //     //     100
            //   )
            // }
            onClick={() => {
              //   const netAmount =
              //     invoice[0]?.collectibles - invoice[0]?.downpaymentValTax;
              const netAmount = invoice[0]?.collectibles - invoice[0]?.tax;
              const withheldAmount =
                (netAmount * change?.withheldPercent) / 100;
              const finalAmount = netAmount - withheldAmount;

              onClickSave(
                0,
                object,
                accounts,
                obj,
                withhold,
                // invoice[0]?.collectibles -
                //   //   invoice[0]?.downpaymentValTax -
                //   //   ((invoice[0]?.collectibles - invoice[0]?.downpaymentValTax) *
                //   invoice[0]?.tax -
                //   ((invoice[0]?.collectibles - invoice[0]?.tax) *
                //     change?.withheldPercent) /
                //     100,
                invoice[0]?.collectibles,
                // -
                //   invoice[0]?.tax -
                //   ((invoice[0]?.collectibles - invoice[0]?.tax) *
                //     change?.withheldPercent) /
                //     100,
                withheldAmount
              );
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}

// import React from "react";
// import {
//   addSchemaUseCase,
//   updateSchemaUseCase,
//   deleteSchemaUseCase,
// } from "../../usecases/schema/usecases";
// import {
//   countObjectUseCase,
//   deleteObjectUseCase,
//   findObjectUseCase,
//   upsertUseCase,
//   getObjectUseCase,
// } from "../../usecases/object";
// import { exportCSVUseCase } from "../../usecases/csv/usecases";
// import { Progress, InfiniteScroll, dialog } from "nq-component";

// import withRouter from "../../withRouter";
// import BaseListPage from "../../base/BaseListPage";
// import NavBar from "../../components/navbar";
// import InputFactory from "../../components/InputFactory";
// import browseFile from "../../browseFile";
// import InvoicesPresenter from "./InvoicesPresenter";
// import InvTable from "../../components/InvTable";
// import printComponent from "../../printComponent";
// import InvoicesPrint from "./components/InvoicesPrint;";

// class InvoicesPage extends BaseListPage {
//   constructor(props) {
//     super(props);
//     this.presenter = new InvoicesPresenter(
//       this,
//       findObjectUseCase(),
//       countObjectUseCase(),
//       deleteObjectUseCase(),
//       upsertUseCase(),
//       getObjectUseCase(),
//       exportCSVUseCase(),
//       addSchemaUseCase(),
//       updateSchemaUseCase(),
//       deleteSchemaUseCase()
//     );
//     this.state = {
//       selected: [],
//       objects: [],
//       schema: [],
//       modifiedObjects: [],
//       dueDates: [],
//       overdueDates: [],
//       totalReceived: 0,
//       services: [],
//       progress: true,
//       fetchobject: null,
//     };

//     this.findObjectUseCase = findObjectUseCase();
//     this.contractPDF = React.createRef();
//   }

//   getCollectionName() {
//     return "invoices_final";
//   }

//   componentDidMount() {
//     this.presenter.componentDidMount();
//     // this.exportPDF();
//   }

//   componentDidUpdate(prevProps, prevState) {
//     if (this.state.objects.length > 0 && !this.state.isObjectsLoaded) {
//       this.changeObj();
//       const totalReceived = this.state.objects.reduce((accumulator, object) => {
//         return accumulator + (object.received || 0);
//       }, 0);
//       if (totalReceived !== this.state.totalReceived) {
//         this.setState({ totalReceived: totalReceived });
//       }
//       this.setState({
//         isObjectsLoaded: true,
//       });
//     }

//     if (
//       this.state.modifiedObjects.length > 0 &&
//       this.state.dueDates.length === 0
//     ) {
//       this.extractDueDates();
//     }
//   }

//   extractDueDates = () => {
//     const {
//       modifiedObjects,
//       dueDates: currentDueDates,
//       overdueDates: currentOverdueDates,
//     } = this.state;
//     const dueDates = [];
//     const overdueDates = [];

//     modifiedObjects.forEach((obj) => {
//       if (obj.due_date.startsWith("Due")) {
//         dueDates.push({
//           due_date: obj.due_date,
//           collectibles: obj.collectibles,
//         });
//       } else if (obj.due_date.startsWith("Overdue")) {
//         overdueDates.push({
//           due_date: obj.due_date,
//           collectibles: obj.collectibles,
//         });
//       }
//     });

//     const dueDatesChanged =
//       JSON.stringify(dueDates) !== JSON.stringify(currentDueDates);
//     const overdueDatesChanged =
//       JSON.stringify(overdueDates) !== JSON.stringify(currentOverdueDates);

//     if (dueDatesChanged || overdueDatesChanged) {
//       this.setState({ dueDates, overdueDates });
//     }
//   };

//   handleFullyPaidChange = () => {
//     this.setState(
//       (prevState) => ({
//         isFullyPaid: !prevState.isFullyPaid,
//       }),
//       () => {
//         console.log(this.state.isFullyPaid);
//       }
//     );
//   };

//   onChange(value, field) {
//     if (!this.state.change || typeof this.state.change !== "object") {
//       this.setState({ change: {} });
//     }

//     const updatedChange = { [field]: value };

//     this.setState((prevState) => ({
//       change: {
//         ...prevState.change,
//         ...updatedChange,
//       },
//     }));
//   }
//   changeObj() {
//     const currentDate = new Date();

//     const modifiedObjects = this.state.objects.map((object) => {
//       const modifiedObject = { ...object };
//       const dueDate = new Date(modifiedObject.due_date);
//       const differenceInTime = dueDate.getTime() - currentDate.getTime();
//       const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

//       if (differenceInDays < 0) {
//         modifiedObject.due_date =
//           "Overdue by " + Math.abs(differenceInDays) + " days";
//       } else if (differenceInDays === 0) {
//         modifiedObject.due_date = "Due today";
//       } else {
//         modifiedObject.due_date = "Due in " + differenceInDays + " days";
//       }

//       return modifiedObject;
//     });

//     if (
//       JSON.stringify(modifiedObjects) !==
//       JSON.stringify(this.state.modifiedObjects)
//     ) {
//       this.setState({ modifiedObjects });
//     }
//   }

//   async exportPDF() {
//     const services = await this.findObjectUseCase.execute("services");
//     console.log("grabe", services);
//     const query = {
//       include: [
//         "All",
//         "client_name",
//         "services",
//         "services.questions",
//         "services.questions.service",
//         "project_name",
//       ],
//     };
//     try {
//       const fetchedData = await findObjectUseCase().execute(
//         "invoices_final",
//         query
//       );
//       const fetchedObjects = fetchedData.find(
//         (item) => item.id === this.state.selected[0]?.id
//       );
//       console.log("lolololo", fetchedObjects);

//       this.setState({ fetchobject: fetchedObjects });
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }

//     this.setState({ services: services });
//     printComponent(
//       this.contractPDF.current,
//       "Invoices_" +
//         this.state.selected[0]?.invoice_num +
//         "_" +
//         this.state.selected[0]?.client_name?.name
//     );
//   }

//   onClickExport() {
//     this.presenter.onClickExport();
//   }
//   onClickItem() {
//     this.presenter.onClickItem();
//   }

//   handleEdit(index) {
//     this.presenter.handleEdit(index);
//   }
//   handleApprove(index) {
//     this.presenter.handleApprove(index);
//   }

//   handleReceive = async (index) => {
//     const object = await this.findObjectUseCase.execute("invoices");
//     const method = await this.findObjectUseCase.execute("paymode");
//     const accounts = await this.findObjectUseCase.execute("gmd_accounts");

//     const formatCurrency = (amount) => {
//       return new Intl.NumberFormat("en-US", {
//         style: "currency",
//         currency: "PHP",
//       }).format(amount);
//     };
//     dialog.fire({
//       html: (
//         <div>
//           <div className="text-start">
//             <p
//               className="fw-bold"
//               style={{ color: "#0057B8", fontSize: "22px" }}
//             >
//               Receive Payment
//             </p>
//             <div
//               className="text-center"
//               style={{ border: "2px solid #EBBD2F", borderRadius: "5px" }}
//             >
//               <p className="m-2" style={{ fontSize: "16px" }}>
//                 {formatCurrency(this.state.objects[index].collectibles)}
//               </p>
//               {/* <p className="m-2" style={{ fontSize: "16px" }}>
//                 {this.state.objects[index].collectibles}
//               </p> */}
//               <p
//                 className="m-0"
//                 style={{
//                   color: "#434343",
//                   fontSize: "12px",
//                   paddingBottom: "10px",
//                 }}
//               >
//                 Remaining Balance
//               </p>
//             </div>
//             <div>
//               <div
//                 style={{
//                   display: "inline-block",
//                   marginRight: "40px",
//                   marginTop: "25px",
//                 }}
//               >
//                 <label
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: "600",
//                     display: "block",
//                   }}
//                 >
//                   Payment Method
//                 </label>
//                 <select
//                   onChange={(event) =>
//                     this.onChange(event.target.value, "payment_method")
//                   }
//                   style={{
//                     width: "115%",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                     boxSizing: "border-box",
//                     padding: "7px",
//                     paddingRight: "15px",
//                     marginTop: "6px",
//                     marginRight: "50px",
//                   }}
//                 >
//                   <option disabled selected hidden>
//                     Select Method
//                   </option>{" "}
//                   {method.map((meth) => {
//                     return (
//                       <option key={meth.id} value={meth.id}>
//                         {meth.name}
//                       </option>
//                     );
//                   })}
//                 </select>
//               </div>
//               <div style={{ marginTop: "0px", display: "inline-block" }}>
//                 <label
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: "600",
//                     display: "block",
//                   }}
//                 >
//                   Account
//                 </label>
//                 <select
//                   onChange={(event) =>
//                     this.onChange(event.target.value, "account")
//                   }
//                   style={{
//                     width: "135%",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                     boxSizing: "border-box",
//                     padding: "7px",
//                     marginTop: "6px",
//                   }}
//                 >
//                   <option disabled selected hidden>
//                     Select an account
//                   </option>{" "}
//                   {accounts.map((account) => {
//                     if (
//                       account.name === "Management money" ||
//                       account.name === "GMD Revolving Fund" ||
//                       account.name === "Metrobank" ||
//                       account.name === "GMD Fund"
//                     ) {
//                       return null;
//                     } else {
//                       return (
//                         <option key={account.id} value={account.id}>
//                           {account.name}
//                         </option>
//                       );
//                     }
//                   })}
//                 </select>
//               </div>

//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   marginTop: "10px",
//                 }}
//               >
//                 <div style={{ marginRight: "10px" }}>
//                   <label style={{ fontSize: "14px", fontWeight: "600" }}>
//                     Amount
//                   </label>
//                   <InputFactory
//                     type="parseNumber"
//                     dynamic={false}
//                     field="amount"
//                     object={object}
//                     placeholder="Enter Amount"
//                     onChange={this.onChange.bind(this)}
//                     required={true}
//                   />
//                 </div>

//                 <div style={{ display: "inline-block", marginTop: "-20px" }}>
//                   <label
//                     style={{
//                       fontSize: "14px",
//                       fontWeight: "600",
//                       display: "block",
//                     }}
//                   >
//                     Reference Number
//                   </label>
//                   <InputFactory
//                     type="Number"
//                     dynamic={false}
//                     field="reference_number"
//                     object={object}
//                     placeholder="Enter Reference No."
//                     onChange={this.onChange.bind(this)}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div
//               style={{
//                 display: "inline-block",
//                 marginRight: "10px",
//                 marginTop: "-10px",
//               }}
//             >
//               <label
//                 style={{
//                   fontSize: "14px",
//                   fontWeight: "600",
//                   display: "block",
//                 }}
//               >
//                 Date
//               </label>
//               <InputFactory
//                 type="Date"
//                 field="date"
//                 placeholder="Add Date"
//                 onChange={this.onChange.bind(this)}
//                 required={true}
//               />
//             </div>
//           </div>
//           <div style={{ textAlign: "center" }}>
//             <button
//               onClick={() => dialog.close()}
//               style={{
//                 marginTop: "20px",
//                 padding: "10px 20px",
//                 borderRadius: "5px",
//                 border: "none",
//                 marginRight: "12px",
//               }}
//             >
//               Cancel
//             </button>
//             <button
//               onClick={() => this.handleSubmit(index, object, accounts)}
//               style={{
//                 padding: "10px 20px",
//                 borderRadius: "5px",
//                 border: "none",
//                 background: "#DAB451",
//               }}
//             >
//               Save
//             </button>
//           </div>
//         </div>
//       ),
//       size: "md",
//       footer: false,
//     });
//   };

//   handleSubmit(index, object, accounts) {
//     const change = this.state.change;
//     this.presenter.handleSubmit(index, object, change, accounts);
//   }
//   onClickItemDelete(index, object) {
//     this.presenter.onClickItemDelete(index);
//   }
//   onCollapse(index, object) {
//     const item = object[index];
//     return (
//       <>
//         {/* <div className="d-flex">
//           <ul className="list-unstyled ms-1 text-truncate">
//             <li>
//               <span className="ms-2 fw-light fw-bold">Invoice #: </span>
//               <span className="text-nowrap">{object.invoice_num}</span>
//             </li>
//             <li>
//               <span className="ms-2 fw-light fw-bold">Client Name: </span>
//               <span className="text-nowrap">{object.client_name?.name}</span>
//             </li>
//             <li>
//               <span className="ms-2 fw-light fw-bold">Amount: </span>
//               <span className="text-nowrap">{object.total}</span>
//             </li>
//             <li>
//               <span className="ms-2 fw-light fw-bold">Received: </span>
//               <span className="text-nowrap">{object.received}</span>
//             </li>
//             <li>
//               <span className="ms-2 fw-light fw-bold">Collectibles: </span>
//               <span className="text-nowrap">{object.collectibles}</span>
//             </li>
//             <li>
//               <span className="ms-2 fw-light fw-bold">VAT: </span>
//               <span className="text-nowrap">{object.tax}</span>
//             </li>
//             <li>
//               <span className="ms-2 fw-light fw-bold">Status: </span>
//               <span className="text-nowrap">{object.statuses}</span>
//             </li>
//             <li>
//               <span className="ms-2 fw-light fw-bold">Project Name: </span>
//               <span className="text-nowrap">{object.project_name?.name}</span>
//             </li>
//           </ul>
//         </div> */}
//         <div className="d-flex">
//           <div className="me-1">
//             {/* <button
//               className="btn btn-primary"
//               onClick={() => this.onClickItem(index)}
//             >
//               <i className="bi bi-pencil-square me-1"></i>EDIT
//             </button> */}
//             {object.statuses === "Pending" && (
//               <button
//                 className="btn btn-primary"
//                 onClick={() => this.handleEdit(index, object)}
//                 // style={{ backgroundColor: "#ebbf38" }}
//               >
//                 {" "}
//                 <i className="bi bi-pencil-square me-1"></i>Edit
//               </button>
//             )}
//           </div>
//           <div className="me-1">
//             {/* <button
//               className="btn btn-danger"
//               onClick={() => this.onClickItemDelete(index, object)}
//             >
//               {" "}
//               <i className="bi bi-trash me-1"></i>DELETE
//             </button> */}
//             {object.statuses === "Approved" && (
//               // <button
//               //   className="btn text-white"
//               //   onClick={() => this.handleReceive(index, object)}
//               //   style={{ backgroundColor: "#ebbf38" }}
//               // >
//               //   {" "}
//               //   <i className="bi bi-plus me-1"></i>Receive
//               // </button>
//               <div className="d-flex">
//                 <div className="me-1">
//                   <button
//                     className="btn text-white"
//                     onClick={() => this.handleReceive(index, object)}
//                     style={{ backgroundColor: "#ebbf38" }}
//                   >
//                     {" "}
//                     <i className="bi bi-plus me-1"></i>Receive
//                   </button>
//                 </div>
//                 <div className="me-1">
//                   <button
//                     className="btn btn-primary"
//                     onClick={() => this.handleEdit(index, object)}
//                     // style={{ backgroundColor: "#ebbf38" }}
//                   >
//                     {" "}
//                     <i class="bi bi-file-earmark-fill"></i> View
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="me-1">
//             {object.statuses === "Partially Paid" && (
//               <div className="d-flex">
//                 <div className="me-1">
//                   <button
//                     className="btn text-white"
//                     onClick={() => this.handleReceive(index, object)}
//                     style={{ backgroundColor: "#ebbf38" }}
//                   >
//                     {" "}
//                     <i className="bi bi-plus me-1"></i>Receive
//                   </button>
//                 </div>
//                 <div className="me-1">
//                   <button
//                     className="btn btn-primary"
//                     onClick={() => this.handleEdit(index, object)}
//                     // style={{ backgroundColor: "#ebbf38" }}
//                   >
//                     {" "}
//                     <i className="bi bi-pencil-square me-1"></i>Edit
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="me-1">
//             {object.statuses === "Paid" && (
//               <button
//                 className="btn text-white"
//                 onClick={() => this.handleEdit(index, object)}
//                 style={{ backgroundColor: "#ebbf38" }}
//               >
//                 {" "}
//                 <i class="bi bi-file-earmark-fill"></i> View
//               </button>
//             )}
//           </div>
//         </div>
//       </>
//     );
//   }

//   prog() {
//     return this.state.progress;
//   }

//   render() {
//     const {
//       index,
//       objects,
//       services,
//       selected,
//       progress,
//       modifiedObjects,
//       totalReceived,
//       fetchobject,
//     } = this.state;
//     console.log("SELECTED", selected);
//     const user = this.getCurrentUser();
//     const schema = this.getSchema(this.getCollectionName());
//     const totalDueCollectibles = this.state.dueDates.reduce(
//       (total, currentDate) => {
//         const collectibles = currentDate.collectibles;

//         if (Array.isArray(collectibles)) {
//           return (
//             total + collectibles.reduce((acc, current) => acc + current, 0)
//           );
//         } else {
//           return total + collectibles;
//         }
//       },
//       0
//     );
//     const totalOverCollectibles = this.state.overdueDates.reduce(
//       (total, currentDate) => {
//         const collectibles = currentDate.collectibles;

//         if (Array.isArray(collectibles)) {
//           return (
//             total + collectibles.reduce((acc, current) => acc + current, 0)
//           );
//         } else {
//           return total + collectibles;
//         }
//       },
//       0
//     );

//     let actionList = [];
//     objects.forEach((object, index) => {
//       let actions = [];

//       switch (object.statuses) {
//         case "Pending":
//           actions.push({
//             label: "Edit",
//             onClick: () => this.handleEdit(index),
//           });
//           actions.push({
//             label: "Receive",
//             onClick: () => this.handleApprove(index),
//           });
//           break;
//         case "Approved":
//           actions.push({
//             label: "Receive",
//             onClick: () => this.handleReceive(index),
//           });
//           actions.push({
//             label: "View",
//             onClick: () => this.handleEdit(index),
//           });
//           break;
//         case "Partially Paid":
//           actions.push({
//             label: "Receive Payment",
//             onClick: () => this.handleReceive(index),
//           });
//           actions.push({
//             label: "View",
//             onClick: () => this.handleEdit(index),
//           });
//           break;
//         case "Paid":
//           actions.push({
//             label: "View",
//             onClick: () => this.handleEdit(index),
//           });
//           break;
//         default:
//           break;
//       }

//       actionList.push(
//         ...actions.map((action, i) => ({ ...action, key: `${index}-${i}` }))
//       );
//     });

//     const formatCurrency = (amount) => {
//       return new Intl.NumberFormat("en-US", {
//         style: "currency",
//         currency: "PHP",
//       }).format(amount);
//     };
//     console.log("objections", fetchobject);

//     return (
//       <>
//         <div className="d-none">
//           <div ref={this.contractPDF}>
//             <InvoicesPrint
//               fetchedObject={fetchobject}
//               object={selected}
//               services={selected[0]?.services}
//             />
//           </div>
//         </div>
//         <NavBar />

//         <div className="overflow-auto">
//           <InfiniteScroll
//             className="h-100"
//             loadMore={this.loadMore.bind(this)}
//             hasMore={!progress}
//           >
//             <div className="p-3 p-lg-4">
//               <div className="d-flex justify-content-between align-items-center">
//                 <h1 className="fw-bold text-capitalize">
//                   {schema.label || this.getCollectionName()}
//                 </h1>
//               </div>
//               <div className="mt-3 d-flex justify-content-between">
//                 <div
//                   className="card p-4"
//                   style={{ opacity: ".7", width: "400px" }}
//                 >
//                   <div>
//                     <ul className="list-unstyled ms-1 text-truncate">
//                       <li>
//                         <span className="ms-2 fw-light fw-bold">Overdue: </span>
//                         <span className="text-nowrap text-danger">
//                           {formatCurrency(totalOverCollectibles)}
//                         </span>
//                       </li>
//                       <li>
//                         <span className="ms-2 fw-light fw-bold">
//                           Not Overdue Yet:{" "}
//                         </span>
//                         <span className="text-nowrap text-orange ">
//                           {formatCurrency(totalDueCollectibles)}
//                         </span>
//                       </li>
//                       <li>
//                         <span className="ms-2 fw-light fw-bold">
//                           Payment Received:{" "}
//                         </span>
//                         <span className="text-nowrap text-success">
//                           {formatCurrency(totalReceived)}
//                         </span>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//                 <div className="d-flex align-items-end">
//                   {selected?.length === 1 ? (
//                     <button
//                       onClick={this.onClickExport.bind(this)}
//                       className="btn bg-sec me-2 px-3 py-2"
//                     >
//                       <i className="bi bi-download me-2"></i>Download PDF
//                     </button>
//                   ) : (
//                     ""
//                   )}
//                   {user.roles.some(
//                     (role) =>
//                       role.name === "masterAdmin" || role.name === "admin"
//                   ) && this.state.selected.length > 0 ? (
//                     <button
//                       className="btn btn-danger me-2 px-3 py-2"
//                       aria-expanded="false"
//                       onClick={this.onClickDeleteSelected.bind(this)}
//                     >
//                       <i className="bi bi-trash"></i>Delete
//                     </button>
//                   ) : (
//                     <></>
//                   )}
//                   <button
//                     onClick={this.onClickItem.bind(this)}
//                     className="btn bg-main px-3 py-2"
//                   >
//                     <i className="bi bi-plus-lg me-2"></i>Create Invoice
//                   </button>
//                 </div>
//               </div>

//               <InvTable
//                 fields={schema.fields}
//                 collapsable={true}
//                 onCollapse={this.onCollapse.bind(this)}
//                 objects={objects}
//                 selectable
//                 // collapsable
//                 // actionsList={actionList}
//                 excludeFields={Object.keys(schema.fields).reduce(
//                   (acc, key) => {
//                     const options = schema.fields[key];
//                     if (options.read === false) {
//                       acc.push(key);
//                     }
//                     switch (options._type || options.type) {
//                       case "Relation":
//                       case "Array":
//                       case "Object":
//                       case "File":
//                         acc.push(key);
//                         break;
//                       default:
//                     }
//                     return acc;
//                   },
//                   [
//                     "acl",
//                     "exclusive_item",
//                     "estimate_name",
//                     "estimate_type",
//                     // "total",
//                     "estimate_num",
//                     // "project_name",
//                     "subtotal",
//                     "discount",
//                     "disc_type",
//                     "asf_type",
//                     "asf",
//                     "services",
//                     "name",
//                     "description",
//                     "qty",
//                     "rate",
//                     "password",
//                     "estimates",
//                     "client_email",
//                     "client_address",
//                     "notes",
//                     "po_num",
//                     "discountType",
//                     "asfType",
//                     // "subTotal",
//                     // "subTotal",
//                     "total",
//                     "exclusive",
//                     "due",
//                     "estimate_num",
//                     "nameService",
//                     "nameDescription",
//                     "descriptionArray",
//                     "downpaymentType",
//                     "downpaymentAmount",
//                     "downpayment",
//                   ]
//                 )}
//                 selected={selected}
//                 onSelect={this.onSelect.bind(this)}
//                 onSelectAll={this.onSelectAll.bind(this)}
//                 progress={progress}
//                 // actions={{
//                 //   handleEdit: this.handleEdit.bind(this),
//                 //   handleApprove: this.handleApprove.bind(this),
//                 //   handleReceive: this.handleReceive.bind(this),
//                 // }}
//                 onClickItem={this.onClickItem.bind(this)}
//                 className="mt-3"
//               />
//             </div>
//           </InfiniteScroll>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(InvoicesPage);

import React from "react";
import {
  addSchemaUseCase,
  updateSchemaUseCase,
  deleteSchemaUseCase,
} from "../../usecases/schema/usecases";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
  getObjectUseCase,
} from "../../usecases/object";
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import { Progress, InfiniteScroll, dialog } from "nq-component";

import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import NavBar from "../../components/navbar";
import InputFactory from "../../components/InputFactory";
import browseFile from "../../browseFile";
import InvoicesPresenter from "./InvoicesPresenter";
import InvTable from "../../components/InvTable";
import printComponent from "../../printComponent";
import InvoicesPrint from "./components/InvoicesPrint;";
import ApproveDialogContent from "./components/ApproveDialogContent";
import Checkbox from "../../components/Checkbox";
import ReceiveInvoiceModal from "./components/modal/ReceiveInvoiceModal";

class InvoicesPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new InvoicesPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase(),
      getObjectUseCase(),
      exportCSVUseCase(),
      addSchemaUseCase(),
      updateSchemaUseCase(),
      deleteSchemaUseCase()
    );
    this.state = {
      selected: [],
      objects: [],
      schema: [],
      modifiedObjects: [],
      dueDates: [],
      overdueDates: [],
      totalReceived: 0,
      services: [],
      progress: true,
      fetchobject: null,
      selectedClient: null,
      filteredProjects: [],
      witholdTax: false,
    };

    this.findObjectUseCase = findObjectUseCase();
    this.contractPDF = React.createRef();
    // this.handleWithold = this.handleWithold.bind(this);
  }

  getCollectionName() {
    return "invoices_final";
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    // this.exportPDF();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.objects.length > 0 && !this.state.isObjectsLoaded) {
      this.changeObj();
      const totalReceived = this.state.objects.reduce((accumulator, object) => {
        return accumulator + (object.received || 0);
      }, 0);
      if (totalReceived !== this.state.totalReceived) {
        this.setState({ totalReceived: totalReceived });
      }
      this.setState({
        isObjectsLoaded: true,
      });
    }

    if (
      this.state.modifiedObjects.length > 0 &&
      this.state.dueDates.length === 0
    ) {
      this.extractDueDates();
    }
  }

  extractDueDates = () => {
    const {
      modifiedObjects,
      dueDates: currentDueDates,
      overdueDates: currentOverdueDates,
    } = this.state;
    const dueDates = [];
    const overdueDates = [];

    modifiedObjects.forEach((obj) => {
      if (obj.due_date.startsWith("Due")) {
        dueDates.push({
          due_date: obj.due_date,
          collectibles: obj.collectibles,
        });
      } else if (obj.due_date.startsWith("Overdue")) {
        overdueDates.push({
          due_date: obj.due_date,
          collectibles: obj.collectibles,
        });
      }
    });

    const dueDatesChanged =
      JSON.stringify(dueDates) !== JSON.stringify(currentDueDates);
    const overdueDatesChanged =
      JSON.stringify(overdueDates) !== JSON.stringify(currentOverdueDates);

    if (dueDatesChanged || overdueDatesChanged) {
      this.setState({ dueDates, overdueDates });
    }
  };

  handleFullyPaidChange = () => {
    this.setState(
      (prevState) => ({
        isFullyPaid: !prevState.isFullyPaid,
      }),
      () => {
        console.log(this.state.isFullyPaid);
      }
    );
  };

  // handleWithold(value) {
  //   this.setState({ witholdTax: value }, () => {
  //     console.log("witholdTax is now:", this.state.witholdTax);
  //   });
  // }

  onChange(value, field) {
    if (!this.state.change || typeof this.state.change !== "object") {
      this.setState({ change: {} });
    }

    const updatedChange = { [field]: value };
    // console.log("withheld tax");
    console.log("withheld tax", updatedChange);

    this.setState((prevState) => ({
      change: {
        ...prevState.change,
        ...updatedChange,
      },
    }));
  }
  changeObj() {
    const currentDate = new Date();

    const modifiedObjects = this.state.objects.map((object) => {
      const modifiedObject = { ...object };
      const dueDate = new Date(modifiedObject.due_date);
      const differenceInTime = dueDate.getTime() - currentDate.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

      if (differenceInDays < 0) {
        modifiedObject.due_date =
          "Overdue by " + Math.abs(differenceInDays) + " days";
      } else if (differenceInDays === 0) {
        modifiedObject.due_date = "Due today";
      } else {
        modifiedObject.due_date = "Due in " + differenceInDays + " days";
      }

      return modifiedObject;
    });

    if (
      JSON.stringify(modifiedObjects) !==
      JSON.stringify(this.state.modifiedObjects)
    ) {
      this.setState({ modifiedObjects });
    }
  }

  async exportPDF() {
    const services = await this.findObjectUseCase.execute("services");
    console.log("grabe", services);
    const query = {
      include: [
        "All",
        "client_name",
        "services",
        "services.questions",
        "services.questions.service",
        "project_name",
      ],
    };
    try {
      const fetchedData = await findObjectUseCase().execute(
        "invoices_final",
        query
      );
      const fetchedObjects = fetchedData.find(
        (item) => item.id === this.state.selected[0]?.id
      );
      console.log("lolololo", fetchedObjects);

      this.setState({ fetchobject: fetchedObjects });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    this.setState({ services: services });
    printComponent(
      this.contractPDF.current,
      "Invoices_" +
        this.state.selected[0]?.invoice_num +
        "_" +
        this.state.selected[0]?.client_name?.name
    );
  }

  toggleModal(object, withhold) {
    console.log("selectedview", object?.id);
    this.setState((prev) => ({
      ...prev,
      modalOpen: !prev.modalOpen,
      selectedView: object?.id,
      withhold: withhold,
    }));
  }

  onClickExport() {
    this.presenter.onClickExport();
  }
  onClickItem() {
    this.presenter.onClickItem();
  }
  onClickApprove(index) {
    dialog.fire({
      html: (
        <ApproveDialogContent
          onApprove={() => {
            this.presenter.onClickApprove(index, "Approved");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }
  handleEdit(index) {
    this.presenter.handleEdit(index);
  }
  handleApprove(index) {
    this.presenter.handleApprove(index);
  }

  // handleReceive = async (index) => {
  handleReceive = async (index, obj, withhold) => {
    const object = await this.findObjectUseCase.execute("invoices");
    const method = await this.findObjectUseCase.execute("paymode");
    const accounts = await this.findObjectUseCase.execute("gmd_accounts");

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };
    console.log("withhold??", withhold);

    dialog.fire({
      html: (
        <div>
          <div className="text-start">
            <p
              className="fw-bold"
              style={{ color: "#0057B8", fontSize: "22px" }}
            >
              Receive Payment
            </p>
            <div
              // className="text-center"
              // style={{ border: "2px solid #EBBD2F", borderRadius: "5px" }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              {/* <p className="m-2" style={{ fontSize: "16px" }}>
                {formatCurrency(this.state.objects[index].collectibles)}
              </p>
              <p className="m-2" style={{ fontSize: "16px" }}>
                {this.state.objects[index].collectibles}
              </p>
              <p
                className="m-0"
                style={{
                  color: "#434343",
                  fontSize: "12px",
                  paddingBottom: "10px",
                }}
              >
                Remaining Balance
              </p> */}
              <div
                className="text-center"
                style={{
                  border: "2px solid #EBBD2F",
                  borderRadius: "5px",
                  flex: 1,
                }}
              >
                <p className="m-2" style={{ fontSize: "16px" }}>
                  {formatCurrency(this.state.objects[index].collectibles)}
                </p>

                <p
                  className="m-0"
                  style={{
                    color: "#434343",
                    fontSize: "12px",
                    paddingBottom: "10px",
                  }}
                >
                  Remaining Balance
                </p>
              </div>
              <div
                className="text-center"
                style={{
                  border: "2px solid #EBBD2F",
                  borderRadius: "5px",
                  flex: 1,
                }}
              >
                <p className="m-2" style={{ fontSize: "16px" }}>
                  {formatCurrency(this.state.objects[index].tax)}
                </p>

                <p
                  className="m-0"
                  style={{
                    color: "#434343",
                    fontSize: "12px",
                    paddingBottom: "10px",
                  }}
                >
                  Tax Amount
                </p>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "inline-block",
                  marginRight: "40px",
                  marginTop: "25px",
                }}
              >
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    display: "block",
                  }}
                >
                  Payment Method
                </label>
                <select
                  onChange={(event) =>
                    this.onChange(event.target.value, "payment_method")
                  }
                  style={{
                    width: "115%",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    boxSizing: "border-box",
                    padding: "7px",
                    paddingRight: "15px",
                    marginTop: "6px",
                    marginRight: "50px",
                  }}
                >
                  <option disabled selected hidden>
                    Select Method
                  </option>{" "}
                  {method.map((meth) => {
                    return (
                      <option key={meth.id} value={meth.id}>
                        {meth.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ marginTop: "0px", display: "inline-block" }}>
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    display: "block",
                  }}
                >
                  Account
                </label>
                <select
                  onChange={(event) =>
                    this.onChange(event.target.value, "account")
                  }
                  style={{
                    width: "135%",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    boxSizing: "border-box",
                    padding: "7px",
                    marginTop: "6px",
                  }}
                >
                  <option disabled selected hidden>
                    Select an account
                  </option>{" "}
                  {accounts.map((account) => {
                    if (
                      account.name === "Management money" ||
                      account.name === "GMD Revolving Fund" ||
                      account.name === "Metrobank" ||
                      account.name === "GMD Fund"
                    ) {
                      return null;
                    } else {
                      return (
                        <option key={account.id} value={account.id}>
                          {account.name}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <label style={{ fontSize: "14px", fontWeight: "600" }}>
                    Amount
                  </label>
                  <InputFactory
                    type="parseNumber"
                    dynamic={false}
                    field="amount"
                    object={object}
                    placeholder="Enter Amount"
                    onChange={this.onChange.bind(this)}
                    required={true}
                  />
                </div>

                <div style={{ display: "inline-block", marginTop: "-20px" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      display: "block",
                    }}
                  >
                    Reference Number
                  </label>
                  <InputFactory
                    type="Number"
                    dynamic={false}
                    field="reference_number"
                    object={object}
                    placeholder="Enter Reference No."
                    onChange={this.onChange.bind(this)}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                marginTop: "-10px",
              }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  display: "block",
                }}
              >
                Date
              </label>
              <InputFactory
                type="Date"
                field="date"
                placeholder="Add Date"
                onChange={this.onChange.bind(this)}
                required={true}
              />
              {/* <label
                className="mt-2"
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  display: "block",
                }}
              >
                Withhold Tax?
              </label>
              <input
                type="checkbox"
                onChange={(e) => this.handleWithold(e.target.checked)}
                style={{ marginRight: "10px" }}
              /> */}
            </div>
            {withhold == true && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <label style={{ fontSize: "14px", fontWeight: "600" }}>
                    Withholding Tax
                  </label>
                  <InputFactory
                    type="Number"
                    dynamic={false}
                    field="withholdTaxPercent"
                    object={object}
                    placeholder="Enter Tax Percent"
                    onChange={this.onChange.bind(this)}
                    required={true}
                  />
                </div>

                <div style={{ display: "inline-block", marginTop: "-20px" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      display: "block",
                    }}
                  >
                    {/* Amount to be paid */}
                    Remaining Balance - Withheld Tax
                  </label>
                  <InputFactory
                    type="parseNumber"
                    dynamic={false}
                    field="newAmount"
                    object={object}
                    disabled
                    // placeholder="Amount"
                    value={formatCurrency(
                      isNaN(
                        // this.state.amount *
                        //   (this.state.withholdTaxPercent / 100)
                        this.state.objects[index].collectibles -
                          this.state.objects[index].collectibles *
                            (this.state.withholdTaxPercent / 100, 0)
                      )
                        ? 0
                        : // this.state.amount *
                          //     (this.state.withholdTaxPercent / 100),
                          this.state.objects[index].collectibles -
                            this.state.objects[index].collectibles *
                              (this.state.withholdTaxPercent / 100, 0),
                      0
                    )}
                    onChange={this.onChange.bind(this)}
                  />
                  {/* <InputFactory
                    type="parseNumber"
                    dynamic={false}
                    field="newAmount"
                    object={object}
                    disabled
                    value={formatCurrency(
                      isNaN(
                        this.state.objects[index].collectibles -
                          this.state.objects[index].tax *
                            (this.state.withholdTaxPercent / 100, 0)
                      )
                        ? 0
                        : this.state.objects[index].collectibles -
                            this.state.objects[index].tax *
                              (this.state.withholdTaxPercent / 100, 0),
                      0
                    )}
                    onChange={this.onChange.bind(this)}
                  /> */}
                </div>
              </div>
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={() => dialog.close()}
              style={{
                marginTop: "20px",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                marginRight: "12px",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => this.handleSubmit(index, object, accounts)}
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                background: "#DAB451",
              }}
            >
              Save
            </button>
          </div>
        </div>
      ),
      size: "md",
      footer: false,
    });
  };

  handleSubmit(index, object, accounts) {
    const change = this.state.change;
    this.presenter.handleSubmit(index, object, change, accounts);
  }
  onClickItemDelete(index, object) {
    this.presenter.onClickItemDelete(index);
  }
  onCollapse(index, object) {
    const item = object[index];
    return (
      <>
        {/* <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Invoice #: </span>
              <span className="text-nowrap">{object.invoice_num}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Client Name: </span>
              <span className="text-nowrap">{object.client_name?.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Amount: </span>
              <span className="text-nowrap">{object.total}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Received: </span>
              <span className="text-nowrap">{object.received}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Collectibles: </span>
              <span className="text-nowrap">{object.collectibles}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">VAT: </span>
              <span className="text-nowrap">{object.tax}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Status: </span>
              <span className="text-nowrap">{object.statuses}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Project Name: </span>
              <span className="text-nowrap">{object.project_name?.name}</span>
            </li>
          </ul>
        </div> */}
        <div className="d-flex">
          <div className="me-1">
            {object.statuses === "Pending" && (
              <>
                <button
                  className="btn text-white me-1"
                  onClick={this.onClickApprove.bind(this, index)}
                  style={{ backgroundColor: "#4aab73" }}
                >
                  {" "}
                  <i className="bi bi-check me-1"></i>Approve
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => this.handleEdit(index, object)}
                  // style={{ backgroundColor: "#ebbf38" }}
                >
                  {" "}
                  <i className="bi bi-pencil-square me-1"></i>Edit
                </button>
              </>
            )}
          </div>
          <div className="me-1">
            {object.statuses === "Approved" && (
              <div className="d-flex">
                <div className="me-1">
                  <button
                    className="btn text-white"
                    // onClick={() => this.handleReceive(index, object)}
                    // onClick={() => this.handleReceive(index, object, false)}
                    onClick={() => this.toggleModal(object, false)}
                    style={{ backgroundColor: "#ebbf38" }}
                  >
                    {" "}
                    <i className="bi bi-plus me-1"></i>Receive
                  </button>
                </div>
                {/* <div className="me-1">
                  <button
                    className="btn text-white"
                    onClick={() => this.handleReceive(index, object, true)}
                    style={{ backgroundColor: "#ebbf38" }}
                  >
                    {" "}
                    <i className="bi bi-plus me-1"></i>Receive Withholding Tax
                  </button>
                </div> */}
                <div className="me-1">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.handleEdit(index, object)}
                    // style={{ backgroundColor: "#ebbf38" }}
                  >
                    {" "}
                    <i class="bi bi-file-earmark-fill"></i> View
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="me-1">
            {object.statuses === "Partially Paid" && (
              <div className="d-flex">
                <div className="me-1">
                  <button
                    className="btn text-white"
                    // onClick={() => this.handleReceive(index, object)}
                    // onClick={() => this.handleReceive(index, object, false)}
                    onClick={() => this.toggleModal(object, false)}
                    style={{ backgroundColor: "#ebbf38" }}
                  >
                    {" "}
                    <i className="bi bi-plus me-1"></i>Receive
                  </button>
                </div>
                {/* <div className="me-1">
                  <button
                    className="btn text-white"
                    onClick={() => this.handleReceive(index, object, true)}
                    style={{ backgroundColor: "#ebbf38" }}
                  >
                    {" "}
                    <i className="bi bi-plus me-1"></i>Receive Withholding Tax
                  </button>
                </div> */}
                {/* <div className="me-1">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.handleEdit(index, object)}
                    // style={{ backgroundColor: "#ebbf38" }}
                  >
                    {" "}
                    <i className="bi bi-pencil-square me-1"></i>Edit
                  </button>
                </div> */}
                <div className="me-1">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.handleEdit(index, object)}
                    // style={{ backgroundColor: "#ebbf38" }}
                  >
                    {" "}
                    <i class="bi bi-file-earmark-fill"></i> View
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="me-1">
            {object.statuses === "Paid" && (
              <button
                className="btn text-white"
                onClick={() => this.handleEdit(index, object)}
                style={{ backgroundColor: "#ebbf38" }}
              >
                {" "}
                <i class="bi bi-file-earmark-fill"></i> View
              </button>
            )}
          </div>
        </div>
      </>
    );
  }

  onChangeFilter(type, value, field) {
    const where = { ...this.state.filters };

    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };

          if (field === "client_name") {
            const newSelectedClient = value.id;
            const hasChanged = this.state.selectedClient !== newSelectedClient;

            this.setState({ selectedClient: newSelectedClient }, () => {
              this.fetchProjectsByClient(newSelectedClient);
              if (!hasChanged) {
                this.fetchProjectsByClient(newSelectedClient);
              }
            });
          }
        } else {
          if (field === "client_name") {
            this.setState(
              {
                selectedClient: null,
                filteredProjects: [],
                filters: {},
              },
              () => {
                delete where["project_name"];
                this.filterSubmit({});
              }
            );
          }
          delete where[field];
        }
        break;

      case "Boolean":
        where[field] = value;
        break;

      default:
        if (value) {
          where[field] = { $regex: value, $options: "i" };
        } else {
          delete where[field];
        }
    }

    this.setState({ filters: where }, () => {
      this.filterSubmit(where);
    });
  }

  async fetchProjectsByClient(clientId) {
    findObjectUseCase()
      .execute("projects")
      .then((projects) => {
        const filteredProjects = projects
          .filter((obj) => obj.client?.id === clientId)
          .map((obj) => ({ id: obj.id, name: obj.name }))
          .filter(
            (value, index, self) =>
              self.findIndex((v) => v.id === value.id) === index
          );

        this.setState({ filteredProjects });
        console.log("filtered projects:", this.state.filteredProjects);
      })
      .catch((err) => console.error("Error fetching projects:", err));
  }

  prog() {
    return this.state.progress;
  }

  render() {
    const {
      index,
      objects,
      services,
      selected,
      progress,
      modifiedObjects,
      totalReceived,
      fetchobject,
    } = this.state;
    console.log("SELECTED", selected);
    console.log("Invoices: ", objects);
    const user = this.getCurrentUser();
    const schema = this.getSchema(this.getCollectionName());
    const totalDueCollectibles = this.state.dueDates.reduce(
      (total, currentDate) => {
        const collectibles = currentDate.collectibles;

        if (Array.isArray(collectibles)) {
          return (
            total + collectibles.reduce((acc, current) => acc + current, 0)
          );
        } else {
          return total + collectibles;
        }
      },
      0
    );
    const totalOverCollectibles = this.state.overdueDates.reduce(
      (total, currentDate) => {
        const collectibles = currentDate.collectibles;

        if (Array.isArray(collectibles)) {
          return (
            total + collectibles.reduce((acc, current) => acc + current, 0)
          );
        } else {
          return total + collectibles;
        }
      },
      0
    );

    let actionList = [];
    objects.forEach((object, index) => {
      let actions = [];

      switch (object.statuses) {
        case "Pending":
          actions.push({
            label: "Edit",
            onClick: () => this.handleEdit(index),
          });
          actions.push({
            label: "Receive",
            onClick: () => this.handleApprove(index),
          });
          break;
        case "Approved":
          actions.push({
            label: "Receive",
            onClick: () => this.handleReceive(index),
          });
          actions.push({
            label: "View",
            onClick: () => this.handleEdit(index),
          });
          break;
        case "Partially Paid":
          actions.push({
            label: "Receive Payment",
            onClick: () => this.handleReceive(index),
          });
          actions.push({
            label: "View",
            onClick: () => this.handleEdit(index),
          });
          break;
        case "Paid":
          actions.push({
            label: "View",
            onClick: () => this.handleEdit(index),
          });
          break;
        default:
          break;
      }

      actionList.push(
        ...actions.map((action, i) => ({ ...action, key: `${index}-${i}` }))
      );
    });

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };
    console.log("objections", fetchobject);

    return (
      <>
        <div className="d-none">
          <div ref={this.contractPDF}>
            <InvoicesPrint
              fetchedObject={fetchobject}
              object={selected}
              services={selected[0]?.services}
            />
          </div>
        </div>
        <NavBar />
        <ReceiveInvoiceModal
          isOpen={this.state.modalOpen}
          toggleModal={this.toggleModal.bind(this)}
          onClickSave={(
            index,
            object,
            accounts,
            obj,
            withhold,
            newAmount,
            withheldAmount
          ) => {
            this.presenter.handleSubmit(
              index,
              object,
              this.state.change,
              accounts,
              obj,
              withhold,
              newAmount,
              withheldAmount
            );
          }}
          obj={this.state.selectedView}
          withhold={this.state.withhold}
          onChange={this.onChange.bind(this)}
          onChangeAccount={(event) =>
            this.onChange(event.target.value, "account")
          }
          onChangePayment={(event) =>
            this.onChange(event.target.value, "payment_method")
          }
          change={this.state.change}
        />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || this.getCollectionName()}
                </h1>
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <div
                  className="card p-4"
                  style={{ opacity: ".7", width: "400px" }}
                >
                  <div>
                    <ul className="list-unstyled ms-1 text-truncate">
                      <li>
                        <span className="ms-2 fw-light fw-bold">Overdue: </span>
                        <span className="text-nowrap text-danger">
                          {formatCurrency(totalOverCollectibles)}
                        </span>
                      </li>
                      <li>
                        <span className="ms-2 fw-light fw-bold">
                          Not Overdue Yet:{" "}
                        </span>
                        <span className="text-nowrap text-orange ">
                          {formatCurrency(totalDueCollectibles)}
                        </span>
                      </li>
                      <li>
                        <span className="ms-2 fw-light fw-bold">
                          Payment Received:{" "}
                        </span>
                        <span className="text-nowrap text-success">
                          {formatCurrency(totalReceived)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  {selected?.length === 1 ? (
                    <button
                      onClick={this.onClickExport.bind(this)}
                      className="btn bg-sec me-2 px-3 py-2"
                    >
                      <i className="bi bi-download me-2"></i>Download PDF
                    </button>
                  ) : (
                    ""
                  )}
                  {user.roles.some(
                    (role) => role.id === "masterAdmin" || role.id === "admin"
                  ) && this.state.selected.length > 0 ? (
                    <button
                      className="btn btn-danger me-2 px-3 py-2"
                      aria-expanded="false"
                      onClick={this.onClickDeleteSelected.bind(this)}
                    >
                      <i className="bi bi-trash"></i>Delete
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <button
                  onClick={this.onClickItem.bind(this)}
                  className="btn bg-main px-3 py-2"
                >
                  <i className="bi bi-plus-lg me-2"></i>Create Invoice
                </button> */}
                {/* <div className="d-flex justify-content-between align-items-center">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}
                </div> */}
                <div className="d-flex justify-content-between align-items-center">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    if (field === "project_name") {
                      options = {
                        ...options,
                        data: this.state.selectedClient
                          ? this.state.filteredProjects
                          : [],
                        placeholder: this.state.selectedClient
                          ? "Select a project"
                          : "Select a project",
                      };

                      return (
                        <select
                          className="form-select ms-1"
                          onChange={(e) =>
                            this.onChangeFilter(
                              "Pointer",
                              { id: e.target.value },
                              "project_name"
                            )
                          }
                          disabled={!this.state.selectedClient}
                        >
                          <option value="">
                            {this.state.selectedClient
                              ? "Select a project"
                              : "Please select a client first"}
                          </option>

                          {this.state.filteredProjects.map((project) => (
                            <option key={project.id} value={project.id}>
                              {project.name}
                            </option>
                          ))}
                        </select>
                      );
                    }
                    if (field === "client_name") {
                      return (
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          {...options}
                        />
                      );
                    }
                  })}
                </div>
              </div>
              <InvTable
                fields={schema.fields}
                onCollapse={this.onCollapse.bind(this)}
                objects={objects}
                selectable
                collapsable
                // actionsList={actionList}
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "exclusive_item",
                    "estimateTotal",
                    "estimate_name",
                    "estimate_type",
                    // "total",
                    "estimate_num",
                    // "project_name",
                    "subtotal",
                    "discount",
                    "disc_type",
                    "asf_type",
                    "asf",
                    "services",
                    "name",
                    "description",
                    "qty",
                    "rate",
                    "password",
                    "estimates",
                    "client_email",
                    "client_address",
                    "notes",
                    "po_num",
                    "discountType",
                    "asfType",
                    // "subTotal",
                    "total",
                    "exclusive",
                    "due",
                    "estimateId",
                    "estimate_num",
                    "nameService",
                    "nameDescription",
                    "descriptionArray",
                    "downpaymentType",
                    "downpaymentAmount",
                    "downpayment",
                    "withholdTax",
                    "addVAT",
                    // // "downpaymentValTax",
                    // "tax",
                    "downpaymentValTax",
                    // "tax",
                    "isWithHold",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                // actions={{
                //   handleEdit: this.handleEdit.bind(this),
                //   handleApprove: this.handleApprove.bind(this),
                //   handleReceive: this.handleReceive.bind(this),
                // }}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(InvoicesPage);

// import BaseFormPresenter from "../../../base/BaseFormPresenter";
// import { dialog } from "nq-component";
// import classNames from "../../../classNames";
// import { findObjectUseCase } from "../../../usecases/object";
// class EstimateInvoiceFormPresenter extends BaseFormPresenter {
//   getCurrentDateFormatted() {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, "0");
//     const day = String(now.getDate()).padStart(2, "0");

//     return `${year}-${month}-${day}`;
//   }

//   componentDidMount() {
//     this.init();

//     this.displayData();
//     this.getObject();
//   }

//   async getObject() {
//     const collection = this.view.getCollectionName();
//     const id = this.object.id || this.view.getObjectId();
//     console.log("ididd", id);
//     if (id) {
//       const params = {
//         include: [
//           "client_name",
//           "name",
//           "services",
//           "services.questions",
//           "services.questions.service",
//           "project_name",
//         ],
//       };
//       try {
//         this.view.showProgress();
//         this.object = await this.getObjectUseCase.execute(collection, id, {
//           params,
//         });
//         this.object.statuses = "Pending";
//         console.log("ovovov", this.object);
//         this.view.hideProgress();
//         this.view.setObject(this.object);
//       } catch (error) {
//         this.view.hideProgress();
//         this.view.showError(error);
//       }
//     }
//   }

//   displayData() {
//     const collection = this.view.getCollectionName();
//     const exam = this.view.getExamId();
//     const query = {
//       where: { id: exam?.id },
//       include: [
//         "All",
//         "services",
//         "services.questions",
//         // "categories.questions.answers",
//         ,
//       ],
//     };
//     console.log("query", query);
//     if (exam?.id) {
//       this.view.showProgress();
//       this.findObjectUseCase
//         .execute(collection, query)
//         .then(([object]) => {
//           this.view.hideProgress();
//           this.object = object;
//           console.log("edit", this.object);
//           this.view.setObject(Object.assign({}, object));
//         })
//         .catch((error) => {
//           this.view.hideProgress();
//           this.view.showError(error);
//         });
//     }
//   }

//   getCurrentDateFormatted() {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, "0");
//     const day = String(now.getDate()).padStart(2, "0");

//     return `${year}-${month}-${day}`;
//   }

//   onChangeObject(object) {
//     this.change = object;
//     this.object = object;
//   }
//   async approved() {
//     const collection = "estimate_forms";
//     const id = this.object.id;
//     const approved = "Approved";
//     const approveAt = this.getCurrentDateFormatted();
//     console.log("Approve Date:", approveAt);

//     const objectToUpsert = {
//       id: id,
//       statuses: approved,
//       approveAt: approveAt,
//     };

//     await this.upsertUseCase.execute(collection, objectToUpsert);

//     this.view.showSuccessSnackbar("Successfully Approved!");
//     await this.view.navigateTo("/estimate");
//   }
//   async save(updatedObject) {
//     const collection = "estimate_forms";
//     const id = this.object.id;

//     console.log("id:", id);

//     try {
//       if (Object.values(this.change).length === 0) {
//         if (typeof updatedObject.subTotal === "string") {
//           updatedObject.subTotal = parseFloat(updatedObject.subTotal);
//         }
//         if (typeof updatedObject.taxAmount === "string") {
//           updatedObject.taxAmount = parseFloat(updatedObject.taxAmount);
//         }
//         if (typeof updatedObject.total === "string") {
//           updatedObject.total = parseFloat(updatedObject.total);
//         }

//         this.view.showSuccessSnackbar("Changes saved!");
//         await this.view.navigateTo("/estimate");

//         await this.upsertUseCase.execute(collection, updatedObject);
//         return;
//       }

//       const updatedChange = {};
//       const { client_name, project_name, discount, ASF } = this.change;
//       if (client_name) {
//         updatedChange.client_name = updatedObject.client_name;
//       }
//       if (project_name) {
//         updatedChange.project_name = updatedObject.project_name;
//       }
//       if (discount) {
//         updatedChange.discount = updatedObject.discount;
//       }
//       if (ASF) {
//         updatedChange.ASF = updatedObject.ASF;
//       }

//       if (Object.keys(updatedChange).length > 0) {
//         const updatedObjectWithChanges = {
//           ...updatedObject,
//           ...updatedChange,
//         };

//         if (typeof updatedObjectWithChanges.subTotal === "string") {
//           updatedObjectWithChanges.subTotal = parseFloat(
//             updatedObjectWithChanges.subTotal
//           );
//         }
//         if (typeof updatedObjectWithChanges.taxAmount === "string") {
//           updatedObjectWithChanges.taxAmount = parseFloat(
//             updatedObjectWithChanges.taxAmount
//           );
//         }
//         if (typeof updatedObjectWithChanges.total === "string") {
//           updatedObjectWithChanges.total = parseFloat(
//             updatedObjectWithChanges.total
//           );
//         }

//         this.view.showSuccessSnackbar("Changes saved!");
//         await this.view.navigateTo("/estimate");

//         await this.upsertUseCase.execute(collection, updatedObjectWithChanges);
//       }
//     } catch (error) {
//       this.view.submissionError(error);
//       this.view.showError(error);
//     }
//   }

//   async onClickApproved() {
//     const collection = "services_final";
//     const id = this.object.id;
//     const approved = "Approved";
//     // const approveAt = this.getCurrentDateFormatted();

//     const objectToUpsert = {
//       id: id,
//       statuses: approved,
//       // approveAt: approveAt
//     };

//     await this.upsertUseCase.execute(collection, objectToUpsert);

//     this.view.showSuccessSnackbar("Successfully Approved!");
//     await this.view.navigateTo("/estimate");
//   }

//   async submit() {
//     const invoice = await findObjectUseCase().execute("invoices_final");

//     let highestInvNo = 0;
//     invoice.forEach((item) => {
//       if (item.invoice_num && item.invoice_num > highestInvNo) {
//         highestInvNo = item.invoice_num;
//       }
//     });
//     const object = this.view.getObject();
//     console.log("OBJECT TO SEND", object);
//     if (object.id) {
//       this.change.id = object.id;
//     } else {
//       object.statuses = "Pending";
//       object.estimate_type = "Fabrication";
//     }

//     const collection = "invoices_final";
//     if (!this.change.received || !object.received) {
//       object.received = 0;
//     }
//     object.statuses = "Pending";
//     object.estimate_type = "Fabrication";
//     object.due_date = this.view.state.due_date;
//     // object.collectibles = object.total;
//     object.collectibles =
//       // object.total - this.view.state.object.downpaymentVal ||
//       // this.change.downpaymentVal ||
//       // 0;
//       // object.total - this.view.state.object.downpaymentVal ||
//       // this.change.downpaymentVal ||
//       // 0;
//       parseFloat(object.downpaymentVal.toFixed(2).toLocaleString());
//     object.invoice_num = highestInvNo + 1;

//     delete object.id;
//     delete object.addVAT;
//     delete object.createdAt;
//     delete object.updatedAt;

//     console.log("OBJECT TO SENDDDDD", object);

//     await this.upsertUseCase.execute(collection, object);
//     this.view.submissionSuccess();
//     this.view.showSuccessSnackbar("Successfully saved!");
//     this.view.navigateBack();

//     await this.view.navigateTo("/estimate");
//   }
//   async cancel() {
//     dialog.fire({
//       html: (
//         <div className="text-end">
//           <div className="text-center p-4">
//             <i
//               className={classNames("bi bi-emoji-frown", "text-danger")}
//               style={{ fontSize: "5rem" }}
//             ></i>
//             <h4 className="fw-bold">Cancelled!</h4>
//             <p className="m-0">Operation has been cancelled.</p>
//             <button
//               className="btn mb-2 mt-2 me-2"
//               style={{ backgroundColor: "#EBBD2F" }}
//               onClick={() => dialog.close()}
//             >
//               Okay
//             </button>
//           </div>
//         </div>
//       ),
//       footer: false,
//     });

//     await this.view.navigateTo("/estimate");
//   }
// }

// export default EstimateInvoiceFormPresenter;

import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { dialog } from "nq-component";
import classNames from "../../../classNames";
import { findObjectUseCase } from "../../../usecases/object";
class EstimateInvoiceFormPresenter extends BaseFormPresenter {
  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  componentDidMount() {
    this.init();
    this.displayData();
    this.getObject();
    this.fetchInvoices();
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    console.log("ididd", id);
    if (id) {
      const params = {
        include: [
          "client_name",
          "name",
          "services",
          "services.questions",
          "services.questions.service",
          "project_name",
        ],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.object.statuses = "Pending";
        console.log("ovovov", this.object);
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async fetchInvoices() {
    try {
      const invoices = await findObjectUseCase().execute("invoices_final", {
        where: { estimateId: this.view.getParams().id },
      });

      const totalSum =
        invoices?.reduce(
          (sum, invoice) => sum + (invoice.downpaymentVal || 0),
          0
        ) || 0;

      this.view.setState({
        invoicesObject: invoices,
        totalInvoicesSum: totalSum,
      });

      return totalSum;
    } catch (error) {
      console.error("Error fetching invoices:", error);
      return 0;
    }
  }

  displayData() {
    const collection = this.view.getCollectionName();
    const exam = this.view.getExamId();
    const query = {
      where: { id: exam?.id },
      include: [
        "All",
        "services",
        "services.questions",
        // "categories.questions.answers",
        ,
      ],
    };
    console.log("query", query);
    if (exam?.id) {
      this.view.showProgress();
      this.findObjectUseCase
        .execute(collection, query)
        .then(([object]) => {
          this.view.hideProgress();
          this.object = object;
          console.log("edit", this.object);
          this.view.setObject(Object.assign({}, object));
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }
  async approved() {
    const collection = "estimate_forms";
    const id = this.object.id;
    const approved = "Approved";
    const approveAt = this.getCurrentDateFormatted();
    console.log("Approve Date:", approveAt);

    const objectToUpsert = {
      id: id,
      statuses: approved,
      approveAt: approveAt,
    };

    await this.upsertUseCase.execute(collection, objectToUpsert);

    this.view.showSuccessSnackbar("Successfully Approved!");
    await this.view.navigateTo("/estimate");
  }
  async save(updatedObject) {
    const collection = "estimate_forms";
    const id = this.object.id;

    console.log("id:", id);

    try {
      if (Object.values(this.change).length === 0) {
        if (typeof updatedObject.subTotal === "string") {
          updatedObject.subTotal = parseFloat(updatedObject.subTotal);
        }
        if (typeof updatedObject.taxAmount === "string") {
          updatedObject.taxAmount = parseFloat(updatedObject.taxAmount);
        }
        if (typeof updatedObject.total === "string") {
          updatedObject.total = parseFloat(updatedObject.total);
        }

        this.view.showSuccessSnackbar("Changes saved!");
        await this.view.navigateTo("/estimate");

        await this.upsertUseCase.execute(collection, updatedObject);
        return;
      }

      const updatedChange = {};
      const { client_name, project_name, discount, ASF } = this.change;
      if (client_name) {
        updatedChange.client_name = updatedObject.client_name;
      }
      if (project_name) {
        updatedChange.project_name = updatedObject.project_name;
      }
      if (discount) {
        updatedChange.discount = updatedObject.discount;
      }
      if (ASF) {
        updatedChange.ASF = updatedObject.ASF;
      }

      if (Object.keys(updatedChange).length > 0) {
        const updatedObjectWithChanges = {
          ...updatedObject,
          ...updatedChange,
        };

        if (typeof updatedObjectWithChanges.subTotal === "string") {
          updatedObjectWithChanges.subTotal = parseFloat(
            updatedObjectWithChanges.subTotal
          );
        }
        if (typeof updatedObjectWithChanges.taxAmount === "string") {
          updatedObjectWithChanges.taxAmount = parseFloat(
            updatedObjectWithChanges.taxAmount
          );
        }
        if (typeof updatedObjectWithChanges.total === "string") {
          updatedObjectWithChanges.total = parseFloat(
            updatedObjectWithChanges.total
          );
        }

        this.view.showSuccessSnackbar("Changes saved!");
        await this.view.navigateTo("/estimate");

        await this.upsertUseCase.execute(collection, updatedObjectWithChanges);
      }
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  async onClickApproved() {
    const collection = "services_final";
    const id = this.object.id;
    const approved = "Approved";
    // const approveAt = this.getCurrentDateFormatted();

    const objectToUpsert = {
      id: id,
      statuses: approved,
      // approveAt: approveAt
    };

    await this.upsertUseCase.execute(collection, objectToUpsert);

    this.view.showSuccessSnackbar("Successfully Approved!");
    await this.view.navigateTo("/estimate");
  }

  // async submit() {
  //   const invoice = await findObjectUseCase().execute("invoices_final");

  //   let highestInvNo = 0;
  //   invoice.forEach((item) => {
  //     if (item.invoice_num && item.invoice_num > highestInvNo) {
  //       highestInvNo = item.invoice_num;
  //     }
  //   });
  //   const object = this.view.getObject();
  //   console.log("OBJECT TO SEND", object);
  //   if (object.id) {
  //     this.change.id = object.id;
  //   } else {
  //     object.statuses = "Pending";
  //     object.estimate_type = "Fabrication";
  //   }

  //   const collection = "invoices_final";
  //   if (!this.change.received || !object.received) {
  //     object.received = 0;
  //   }
  //   object.statuses = "Pending";
  //   object.estimate_type = "Fabrication";
  //   object.due_date = this.view.state.due_date;
  //   object.collectibles =
  //     // object.total - this.view.state.object.downpaymentVal ||
  //     // this.change.downpaymentVal ||
  //     // 0;
  //     parseFloat(object.downpaymentVal.toFixed(2).toLocaleString());
  //   object.invoice_num = highestInvNo + 1;

  //   delete object.id;
  //   delete object.addVAT;
  //   delete object.createdAt;
  //   delete object.updatedAt;

  //   console.log("OBJECT TO SENDDDDD", object);

  //   await this.upsertUseCase.execute(collection, object);
  //   this.view.submissionSuccess();
  //   this.view.showSuccessSnackbar("Successfully saved!");
  //   this.view.navigateBack();

  //   await this.view.navigateTo("/estimate");
  // }
  async submit() {
    const object = this.view.getObject();
    // if (!object.downpaymentAmount || object.downpaymentAmount === 0) {
    if (
      // object.total - this.view.state.totalInvoicesSum <
      object.estimateTotal - this.view.state.totalInvoicesSum <
      object.downpaymentVal
    ) {
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-exclamation-triangle",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Invalid Invoice</h4>
              <p className="m-0">
                Invoice amount exceeds the current estimated total.
              </p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Okay
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
    } else if (!object.downpaymentAmount || object.downpaymentAmount === 0) {
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-exclamation-triangle",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Invalid Invoice</h4>
              <p className="m-0">Invoice Amount is required.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Okay
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
    } else {
      const invoice = await findObjectUseCase().execute("invoices_final");

      let highestInvNo = 0;
      invoice.forEach((item) => {
        if (item.invoice_num && item.invoice_num > highestInvNo) {
          highestInvNo = item.invoice_num;
        }
      });
      const object = this.view.getObject();
      console.log("OBJECT TO SEND", object);
      if (object.id) {
        this.change.id = object.id;
      } else {
        object.statuses = "Pending";
        object.estimate_type = "Fabrication";
      }

      const collection = "invoices_final";
      if (!this.change.received || !object.received) {
        object.received = 0;
      }
      object.estimateId = this.view.state.object.id;
      object.statuses = "Pending";
      object.estimate_type = "Fabrication";
      object.due_date = this.view.state.due_date;
      object.collectibles =
        // object.total - this.view.state.object.downpaymentVal ||
        // this.change.downpaymentVal ||
        // 0;
        parseFloat(object.downpaymentVal.toFixed(2).toLocaleString());
      object.downpaymentValTax = parseFloat(
        (object.downpaymentVal * 0.12).toFixed(2)
      );
      // object.tax = parseFloat((object.downpaymentVal * 0.12).toFixed(2));
      // downpaymentAmount
      // downpaymentType
      // object.tax = parseFloat((object.downpaymentVal * 0.12).toFixed(2));

      if (object.downpaymentType === "percent") {
        object.tax = parseFloat(
          ((object.downpaymentAmount / 100) * object.tax).toFixed(2)
        );
      } else {
        let netAmount = parseFloat(
          (object.downpaymentAmount / 1.12).toFixed(2)
        );

        // Compute the VAT
        object.tax = parseFloat(
          (object.downpaymentAmount - netAmount).toFixed(2)
        );
        // object.tax = parseFloat((object.tax * 0.12).toFixed(2));
      }
      object.invoice_num = highestInvNo + 1;

      delete object.id;
      // delete object.addVAT;
      delete object.createdAt;
      delete object.updatedAt;

      object.services = object.services.filter(
        (service) => service.__operation !== "REMOVE"
      );

      let updatedDescriptions = sessionStorage.getItem(
        "updatedDescriptionsInvoices"
      );

      if (updatedDescriptions) {
        updatedDescriptions = JSON.parse(updatedDescriptions);

        // object.descriptionArray = updatedDescriptions.map((desc) => {
        //   return {
        //     description: desc.description,
        //     categoryIndex: desc.categoryIndex,
        //   };
        // });
        object.descriptionArray = updatedDescriptions
          .filter((desc) =>
            object.services.some(
              (service, index) => index === desc.categoryIndex
            )
          )
          .map((desc) => ({
            description: desc.description,
            categoryIndex: desc.categoryIndex,
          }));

        console.log("UD", updatedDescriptions);
      } else {
        console.log("No updatedDescriptions found in sessionStorage");
      }

      console.log("OBJECT TO SENDDDDD", object);

      await this.upsertUseCase.execute(collection, object);
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      sessionStorage.removeItem("updatedDescriptionsInvoices");
      // this.view.navigateBack();

      this.view.navigateTo("/invoices");
    }
  }
  async cancel() {
    dialog.fire({
      html: (
        <div className="text-end">
          <div className="text-center p-4">
            <i
              className={classNames("bi bi-emoji-frown", "text-danger")}
              style={{ fontSize: "5rem" }}
            ></i>
            <h4 className="fw-bold">Cancelled!</h4>
            <p className="m-0">Operation has been cancelled.</p>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F" }}
              onClick={() => dialog.close()}
            >
              Okay
            </button>
          </div>
        </div>
      ),
      footer: false,
    });

    await this.view.navigateTo("/estimate");
  }
}

export default EstimateInvoiceFormPresenter;

import { dialog } from "nq-component";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import classNames from "../../classNames";
import {
  aggregateUseCase,
  findObjectUseCase,
  getObjectUseCase,
} from "../../usecases/object";

class BudgetRequestFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  formatNumber = (value) => {
    if (!value) return "";
    const [integerPart, decimalPart] = value.split(".");
    const formattedInteger = parseInt(
      integerPart.replace(/,/g, "") || "0",
      10
    ).toLocaleString("en-US");
    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };

  async save() {
    const collection = this.view.getCollectionName();
    const user = this.view.getCurrentUser();
    // const budgetRequest = this.object;

    // const itemsName1 = budgetRequest.items_list[0]?.name || "";
    // const itemsAmount1 = budgetRequest.items_list[0]?.amounts || "";
    // const itemsName2 = budgetRequest.items_list[1]?.name || "";
    // const itemsAmount2 = budgetRequest.items_list[1]?.amounts || "";
    // const itemsName3 = budgetRequest.items_list[2]?.name || "";
    // const itemsAmount3 = budgetRequest.items_list[2]?.amounts || "";
    // const itemsName4 = budgetRequest.items_list[3]?.name || "";
    // const itemsAmount4 = budgetRequest.items_list[3]?.amounts || "";
    // const itemsName5 = budgetRequest.items_list[4]?.name || "";
    // const itemsAmount5 = budgetRequest.items_list[4]?.amounts || "";
    // const itemsName6 = budgetRequest.items_list[5]?.name || "";
    // const itemsAmount6 = budgetRequest.items_list[5]?.amounts || "";
    // const itemsName7 = budgetRequest.items_list[6]?.name || "";
    // const itemsAmount7 = budgetRequest.items_list[6]?.amounts || "";
    // const itemsName8 = budgetRequest.items_list[7]?.name || "";
    // const itemsAmount8 = budgetRequest.items_list[7]?.amounts || "";
    // const itemsName9 = budgetRequest.items_list[8]?.name || "";
    // const itemsAmount9 = budgetRequest.items_list[8]?.amounts || "";
    // const itemsName10 = budgetRequest.items_list[9]?.name || "";
    // const itemsAmount10 = budgetRequest.items_list[9]?.amounts || "";

    const budgetRequest = this.change;
    const items = budgetRequest.items_list || [];

    const itemNames = items.map(
      (item, index) => item.name || `itemsName${index + 1}`
    );
    const itemAmount = items.map(
      (item, index) => item.amounts || `itemsAmount${index + 1}`
    );
    const itemAmounts = items.map(
      (item, index) => item.amounts || `itemsAmounts${index + 1}`
    );

    // Log the new object
    // console.log("Items as Object", items1);

    // const particulars = budgetRequest.items_list.map((item) => ({
    //   name: item.name,
    //   amounts: item.amounts,
    // }));

    // const totalAmount = budgetRequest.items_list
    //   .map((items) => Number(items.amounts.replace(/,/g, "")))
    //   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalAmount = this.view.calculateTotal();

    // const latestBudgetRequests = await findObjectUseCase().execute(collection);

    // const latestBudgetRequest = latestBudgetRequests.sort(
    //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    // )[0];

    // let incrementedRequestNumber;
    // if (latestBudgetRequest) {
    //   // const objectCreatedAt = new Date(latestBudgetRequest.createdAt);
    //   const currentDate = new Date();
    //   const isFirstDayOfMonth = currentDate.getDate() === 1;
    //   if (!isFirstDayOfMonth) {
    //     incrementedRequestNumber = latestBudgetRequest.requestNumber + 1;
    //   } else {
    //     const currentMonth = currentDate.getMonth();
    //     const currentYear = currentDate.getFullYear();
    //     let maxRequestNumber = 0;
    //     for (const request of latestBudgetRequests) {
    //       const requestDate = new Date(request.createdAt);
    //       if (
    //         requestDate.getMonth() === currentMonth &&
    //         requestDate.getFullYear() === currentYear
    //       ) {
    //         maxRequestNumber = Math.max(
    //           maxRequestNumber,
    //           request.requestNumber
    //         );
    //       }
    //     }
    //     incrementedRequestNumber = maxRequestNumber + 1;
    //   }
    // } else {
    //   incrementedRequestNumber = 1;
    // }

    const latest = await aggregateUseCase().execute("budget_request", [
      {
        $sort: { createdAt: -1 },
      },
      {
        $limit: 1,
      },
    ]);

    console.log(new Date().getMonth());
    console.log(new Date(latest[0].createdAt).getMonth());

    let incrementedRequestNumber = latest[0].requestNumber;
    if (new Date().getMonth() === new Date(latest[0].createdAt).getMonth()) {
      incrementedRequestNumber++;
    } else {
      incrementedRequestNumber = 1;
    }

    if (!Array.isArray(this.change.liqui)) {
      this.change.liqui = [];
    }
    //const proj = await getObjectUseCase().execute("budget_request",this.object.id);
    console.warn("asd", this.view.state);
    if (this.object.id) {
      this.change.id = this.object.id;
      this.change.statuses = "Pending";
      this.change.totalAmount = totalAmount;
      this.change.project_name = this.view.state.project_name;
      this.change.items_list = this.view.state.items;
      // this.change.items_list = this.view.state.items.map((item) => ({
      //   ...item,
      //   amounts: this.formatNumber(String(item.amounts)),
      // }));
      console.log(this.change, "huhu");
    } else {
      this.change.acl = this.view.getAcl();
      this.change.requestNumber = incrementedRequestNumber;
      this.change.statuses = "Pending";
      this.change.creditedTo = user;
      //this.change.projectString = this.change.project_name?.name;
      this.change.requesteeString = user.fullName;
      this.change.totalAmount = totalAmount;
      // this.change.items1 = `${"->"} ${itemsName1} : ${itemsAmount1}` || "";
      // this.change.items2 = itemsName2
      //   ? `${"->"} ${itemsName2} : ${itemsAmount2}`
      //   : "";
      // this.change.items3 = itemsName3
      //   ? `${"->"} ${itemsName3} : ${itemsAmount3}`
      //   : "";
      // this.change.items4 = itemsName4
      //   ? `${"->"} ${itemsName4} : ${itemsAmount4}`
      //   : "";
      // this.change.items5 = itemsName5
      //   ? `${"->"} ${itemsName5} : ${itemsAmount5}`
      //   : "";
      // this.change.items6 = itemsName6
      //   ? `${"->"} ${itemsName6} : ${itemsAmount6}`
      //   : "";
      // this.change.items7 = itemsName7
      //   ? `${"->"} ${itemsName7} : ${itemsAmount7}`
      //   : "";
      // this.change.items8 = itemsName8
      //   ? `${"->"} ${itemsName8} : ${itemsAmount8}`
      //   : "";
      // this.change.items9 = itemsName9
      //   ? `${"->"} ${itemsName9} : ${itemsAmount9}`
      //   : "";
      // this.change.items10 = itemsName10
      //   ? `${"->"} ${itemsName10} : ${itemsAmount10}`
      //   : "";
      this.change.items_list = this.view.state.items;
      // this.change.items_list = this.view.state.items.map((item) => ({
      //   ...item,
      //   amounts: this.formatNumber(String(item.amounts)),
      // }));
      items.forEach((item, index) => {
        const itemKey = `items${index + 1}`;

        this.change[itemKey] =
          item.name && item.amounts
            ? `-> ${item.name} : ${String(item.amounts)}`
            : "";
      });

      this.change.liqui?.push({ requestNumber: incrementedRequestNumber });
      const project_name = this.view.state.selectedProject;
      this.change.project_name = project_name;
    }

    console.log("This.change", this.change);
    try {
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    // if (Object.values(this.change).length === 0) {
    if (
      Object.values(this.change).length === 0 &&
      this.view.state.items == this.view.state.object.items_list
    ) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }

    this.view.submitting();

    const confirmAndSave = async () => {
      try {
        await this.save();
        dialog.close();
        this.view.submissionSuccess();

        await this.view.navigateTo("/budget-request");
        this.change = {};
      } catch (error) {
        this.view.submissionError(error);
        this.view.showError(error);
      }
    };

    // Modified dialog.fire section
    dialog.fire({
      html: (
        <div className="text-end">
          <div className="text-center">
            <i
              className={classNames("bi bi-file-earmark-check", "text-warning")}
              style={{ fontSize: "5rem" }}
            ></i>
            <h4 className="fw-bold">Success!</h4>
            <p className="m-0">
              Please wait for the admin to approve your budget request.
            </p>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F" }}
              onClick={confirmAndSave}
            >
              Confirm
            </button>
            <button
              className="btn btn-light mb-2 mt-2"
              onClick={() => dialog.close()}
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }

  async getObject2() {
    const collection = "clients";
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = { include: ["all"] };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  onChangeFilter(object) {
    this.object = object;
    this.getObject2();
  }
}

export default BudgetRequestFormPresenter;

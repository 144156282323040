import React from "react";
import {
  Button,
  InfiniteScroll,
  Progress,
  Search,
  SelectSearch,
  dialog,
} from "nq-component";
import BaseListPage from "../../../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../../usecases/object";

import ProTable from "../../../../components/ProTable";
import ProjectTabPresenter from "./ProjectTabPresenter";
import printComponent from "../../../../printComponent";
import { browseFile } from "nq";
import ApproveDialogContent from "./components/ApproveDialogContent";
import DeclineDialogContent from "./components/DeclineDialogContent";
import InputFactory from "../../../../components/InputFactory";
import AddNewEstimate from "../../../estimate/add-new-estimate/AddNewEstimate";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class ProjectTab extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ProjectTabPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.fieldsToDisplay = [
      "roles",
      "fullName",
      "total",
      "status",
      "statuses",
      "requestNumber",
      "link",
      "employee",
      // "received",
      // "invoice_num",

      this.props.tabCollection !== "project_designs" && "project_name",
      this.props.tabCollection !== "services_final" &&
        this.props.tabCollection !== "invoices_final" &&
        "name",
      this.props.tabCollection !== "invoices_final" &&
        this.props.tabCollection !== "services_final" &&
        "estimate_num",
      this.props.tabCollection !== "users" &&
        this.props.tabCollection !== "invoices_final" &&
        this.props.tabCollection !== "services_final" &&
        "createdAt",
    ];
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
    this.contractPDF = React.createRef();
  }
  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  componentDidMount() {
    this.getCollectionName();
    this.presenter.componentDidMount();
  }
  getCollectionName() {
    return this.props.tabCollection;
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  onChangeFilterTab(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmitTab(where);
  }

  filterSubmitTab(where) {
    console.log(where);
    this.presenter.filterSubmitTab(where);
  }

  onClickAdd() {
    const projectID = this.props.project;
    const projectName = this.props.project.name;
    const clientID = projectID.client?.id;
    this.presenter.onClickAdd(projectID.id, clientID, projectName);
    console.log("second", projectID, clientID);
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onClickApprove(index) {
    dialog.fire({
      html: (
        <ApproveDialogContent
          onApprove={() => {
            this.presenter.onClickStatus(index, "Approved");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }
  onClickDecline(index) {
    dialog.fire({
      html: (
        <DeclineDialogContent
          onDecline={() => {
            this.presenter.onClickStatus(index, "Declined");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onCollapseDesign(index, object) {
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();

    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Designer: </span>
              <span className="text-nowrap">{object.employee?.fullName}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Design Link: </span>
              <span className="text-nowrap">
                <a
                  href={object.link}
                  style={{ color: "#cf6f00" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-paperclip"></i>
                  {object.link}
                </a>
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Description: </span>
              <span className="text-nowrap">{object.description}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Date: </span>
              <span className="text-nowrap">
                {formatDate(object.createdAt)}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Status: </span>
              <span
                className={`text-nowarp fw-bold ${
                  object.statuses === "Cancelled"
                    ? "text-primary"
                    : object.statuses === "Approved"
                    ? "text-success"
                    : object.statuses === "Declined"
                    ? "text-danger"
                    : object.statuses === "For Approval"
                    ? "text-warning"
                    : ""
                }`}
                style={object.statuses === "Paid" ? { color: "#CF6F00" } : {}}
              >
                {object.statuses}
              </span>
            </li>
          </ul>
        </div>

        <div>
          {object.statuses === "For Approval" && (
            <>
              {!user.roles.some(
                (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
              ) && (
                <button
                  onClick={this.onClickItem.bind(this, index)}
                  className="btn btn-primary mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F", border: 0 }}
                >
                  View
                </button>
              )}
            </>
          )}
          {object.statuses === "Disapproved" && (
            <>
              {!user.roles.some(
                (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
              ) && (
                <button
                  onClick={this.onClickItem.bind(this, index)}
                  className="btn btn-primary mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F", border: 0 }}
                >
                  Edit
                </button>
              )}
            </>
          )}
        </div>
        {object.statuses === "For Approval" && (
          <>
            {user.roles.some(
              (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
            ) && (
              <>
                <button
                  onClick={this.onClickApprove.bind(this, index)}
                  className="btn btn-primary mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#4aab73", border: 0 }}
                >
                  <i class="bi bi-check"></i> APPROVE
                </button>
                <button
                  onClick={this.onClickDecline.bind(this, index)}
                  className="btn btn-danger mb-2 mt-2 me-2"
                  style={{ border: 0 }}
                >
                  <i class="bi bi bi-x"></i> DECLINE
                </button>
                <button
                  onClick={this.onClickItem.bind(this, index)}
                  className="btn btn-primary"
                  style={{ backgroundColor: "#EBBD2F", border: 0 }}
                >
                  <i class="bi bi-pencil-square"></i> EDIT
                </button>
              </>
            )}
          </>
        )}
      </div>
    );
  }

  onCollapseExpense(index, object) {
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">BR#: </span>
              <span className="text-nowrap">{object.requestNumber}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">DATE: </span>
              <span className="text-nowrap">
                {formatDate(object.createdAt)}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">ACCOUNT: </span>
              <span className=" text-nowrap">{object.accounts?.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">CHART OF ACCOUNTS: </span>
              <span className="text-nowrap">{object.chart_accounts}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">ITEMS: </span>
              <span className="text-nowrap">
                {object.items?.map((i) => (
                  <>
                    <ul>
                      <li>
                        <span>
                          {i.name} -{" "}
                          {formatCurrency(Number(i.amounts?.replace(/,/g, "")))}
                        </span>
                      </li>
                    </ul>
                  </>
                ))}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">AMOUNT: </span>
              <span className=" text-nowrap">
                {formatCurrency(object.amount)}
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const projectID = this.props.project;
    const clientID = projectID.client?.id;
    const { showModal } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    console.log("objects are not valid", objects);
    const { tabCollection, project } = this.props;
    console.log("first", project, project.client);
    // console.log("hello", projectName, projectId, clientId, tabCollection);
    const user = this.getCurrentUser();

    if (!schema) return <Progress />;
    return (
      <>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="fw-bold fs-4 text-capitalize">
                {tabCollection === "project_designs" && "Designs"}
                {tabCollection === "users" && "Employees"}
                {tabCollection === "expense" && "Expenses"}
                {tabCollection === "services_final" && "Estimates"}
                {tabCollection === "invoices_final" && "Invoices"}
              </h1>
              {/* {tabCollection != "expense" && tabCollection != "users" && (
                <Button
                  className="btn m-1"
                  style={{ backgroundColor: "#dab451" }}
                  onClick={
                    tabCollection !== "services_final"
                      ? this.onClickAdd.bind(this)
                      : this.toggleModal
                  }
                >
                  <i className="bi bi-plus fs-6   " /> Create/Add{" "}
                  {tabCollection === "project_designs" && "Design"}
                  {tabCollection === "services_final" && "Estimate"}
                  {tabCollection === "invoices_final" && "Invoice"}
                </Button>
              )} */}
              {tabCollection != "expense" &&
                tabCollection != "users" &&
                tabCollection != "invoices_final" && (
                  <Button
                    className="btn m-1"
                    style={{ backgroundColor: "#dab451" }}
                    onClick={
                      tabCollection !== "services_final"
                        ? this.onClickAdd.bind(this)
                        : this.toggleModal
                    }
                  >
                    <i className="bi bi-plus fs-6   " /> Create/Add{" "}
                    {tabCollection === "project_designs" && "Design"}
                    {tabCollection === "services_final" && "Estimate"}
                  </Button>
                )}
            </div>
            <AddNewEstimate
              pid={projectID.id}
              cid={clientID}
              showModal={showModal}
              toggleModal={this.toggleModal}
            />
            {Object.keys(schema.filtersTab || {}).map((field) => {
              let { type, ...options } = schema.filtersTab[field];

              return (
                <InputFactory
                  key={field}
                  className="ms-1"
                  type={type}
                  field={field}
                  where={{}}
                  onChange={this.onChangeFilterTab.bind(this, type)}
                  {...options}
                />
              );
            })}
            <div className="p-3 p-lg-4">
              <ProTable
                user={user}
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                selectable
                collapsable={
                  tabCollection === "project_designs"
                  // ||
                  // tabCollection === "expense"
                }
                onCollapse={
                  (tabCollection === "project_designs" &&
                    this.onCollapseDesign.bind(this)) ||
                  (tabCollection === "expense" &&
                    this.onCollapseExpense.bind(this))
                }
                excludeFields={Object.keys(schema.fields).filter(
                  (key) => !this.fieldsToDisplay.includes(key)
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default ProjectTab;

import React from "react";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";
import ExpensePresenter from "./ExpensePresenter";
import Table from "../../components/Table";
import PoTable from "../../components/PoTable";
import toast, { Toaster } from "react-hot-toast";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

class ExpensePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ExpensePresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase(),
      getObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      filter: {},
      progress: true,
      total: 0,
      count: 0,
    };
  }

  getCollectionName() {
    return "expense";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  filterSubmit2(where) {
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.setState({ filter: where });
    this.filterSubmit(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    console.log("w", where);
    this.setState({ filter: where });
    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.setState({ filter: where });
    this.filterSubmit3(where);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickApprove(index) {
    this.presenter.onClickStatus(index, "approved");
  }

  onClickLiquidation(index) {
    this.presenter.onClickStatus(index, "liquidation");
  }

  onClickApproveLiquidation(index) {
    this.presenter.onClickStatus(index, "paid");
  }

  onClickDisapproved(index) {
    this.presenter.onClickStatus(index, "disapproved");
  }

  onClickLiquidate(index, field) {
    this.presenter.onClickLiquidate(index, field);
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onCollapse(index, object) {
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">DATE: </span>
              <span className="text-nowrap">
                {formatDate(object.createdAt)}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">ITEMS: </span>
              <span className="text-nowrap">
                {object.items.map((i) => (
                  <ul key={i.name}>
                    <li>
                      <span>
                        {i.name} -{" "}
                        {formatCurrency(
                          // parseFloat(i.amounts) *
                          //   // parseFloat(i.amounts?.split(",").join("")) *
                          //   // parseFloat(i.amounts?.split(",").join("")) *
                          //   i.quantity
                          parseFloat(
                            typeof i.amounts === "string" ||
                              typeof i.amounts === "String"
                              ? i.amounts.split(",").join("")
                              : i.amounts || 0
                          ) * i.quantity
                        )}
                      </span>
                    </li>
                  </ul>
                ))}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">ACCOUNT: </span>
              <span className=" text-nowrap">{object.accounts?.name}</span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">AMOUNT: </span>
              <span className=" text-nowrap">
                {formatCurrency(object.amount)}
              </span>
            </li>

            {object.chart_accounts && (
              <li>
                <span className="ms-2 fw-light fw-bold">
                  CHART OF ACCOUNT:{" "}
                </span>
                <span className="text-nowrap">
                  {object.chart_accounts?.name}
                </span>
              </li>
            )}
            {object.requestNumber && (
              <li>
                <span className="ms-2 fw-light fw-bold">
                  BUDGET REQUEST NUMBER:{" "}
                </span>
                <span className="text-nowrap">{object.requestNumber}</span>
              </li>
            )}
            {object.purchaseOrderNumber && (
              <li>
                <span className="ms-2 fw-light fw-bold">
                  PURCHASE ORDER NUMBER:{" "}
                </span>
                <span className="text-nowrap">
                  {object.purchaseOrderNumber}
                </span>
              </li>
            )}
          </ul>
        </div>
        <>
          <button
            onClick={this.onClickItem.bind(this, index)}
            className="btn btn-primary btn-sm"
          >
            EDIT
          </button>
        </>
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  // Helper function to format the currency
  formatCurrency = (amount) => {
    return `PHP ${amount.toFixed(2)}`;
  };

  // This function will convert the data to CSV string
  convertToCSV = async (arrayOfObjects) => {
    const ao = await findObjectUseCase().execute("expense", {
      include: ["all"],
    });

    // Sorting objects by date
    // ao.sort((a, b) => {
    //     const dateA = new Date(a.createdAt);
    //     const dateB = new Date(b.createdAt);
    //     return dateA.getTime() - dateB.getTime();
    // });

    console.log("ao", ao);
    const rows = [];

    // Headers
    const headers = [
      "Date Created",
      "Client Name",
      "Project Name",
      "BR #",
      "Item Supplier",
      "Item TIN #",
      "Item Name",
      "Item Amount",
      "Chart of Accounts Name",
      // "Item Name",
      // "Item Quantity",
      // "Item Supplier",
      // "Item Tin#"
    ];

    rows.push(headers.join(","));

    // Convert each object to CSV row
    await Promise.all(
      ao.map(async (obj) => {
        // Sort items by createdAt
        // obj.items.sort((a) => {
        //     const dateA = new Date(a.createdAt);

        //     return dateA.getTime()
        // });

        await Promise.all(
          obj.items.map(async (item) => {
            console.error(obj);
            const csvRow = [];
            csvRow.push(`"${obj?.createdAt}"`);
            csvRow.push(`"${obj?.client_name?.name}"`);
            csvRow.push(`"${obj?.project_name?.name}"`);
            csvRow.push(`"${obj?.requestNumber || ""}"`);

            // Process each item
            const chartOfAccountsId = item["chart_of_accounts"]?.id || "";
            const findCoa = await getObjectUseCase().execute(
              "chart_of_accounts",
              chartOfAccountsId
            );
            console.warn("wahahaha", findCoa.name);

            csvRow.push(`"${item.supplier || ""}"`);
            csvRow.push(`"${item["tin#"] || ""}"`);
            csvRow.push(`"${item["name"] || ""}"`);
            csvRow.push(`"${item["amounts"] || ""}"`);
            csvRow.push(`"${findCoa.name || ""}"`);

            // Additional item fields
            // csvRow.push(`"${item["name"] || ""}"`);
            // csvRow.push(`"${item["quantity"] || ""}"`);
            // csvRow.push(`"${item["supplier"] || ""}"`);
            // csvRow.push(`"${item["tin#"] || ""}"`);

            rows.push(csvRow.join(","));
          })
        );
      })
    );

    return rows.join("\n");
  };

  // This function triggers the download of the CSV file
  downloadCSV = (csvString, filename) => {
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // This is the function that you would call when the export button is clicked
  onClickExport() {
    toast.promise(
      this.presenter.exportToCSV(),
      {
        loading: "Exporting...",
        success: <b>Successfully Exported!</b>,
        error: <b>Failed to Export.</b>,
      },
      { position: "top-right" }
    );
  }

  render() {
    const user = this.getCurrentRoles();
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;

    const { isOffcanvasOpen } = this.state;
    if (!schema) return <Progress />;

    console.log("OBJECT", objects);

    return (
      <>
        <NavBar />
        <Toaster />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects?.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Expenses</h1>

                <div className="d-block d-md-none mt-2">
                  <i
                    className="bi bi-filter"
                    style={{ fontSize: "25px", color: "#EBBD2F" }}
                    onClick={this.toggleOffcanvas}
                    aria-controls="offcanvasRight"
                  ></i>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>

                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters3 || {}).map((field) => {
                          let { type, ...options } = schema.filters3[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1 "
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter3.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}
                        <div className="">
                          {" "}
                          <DateRange
                            onChange={this.onChangeDate.bind(this)}
                            field="due_date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];

                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}

                {Object.keys(schema.filters2 || {}).map((field) => {
                  let { type, ...options } = schema.filters2[field];

                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter2.bind(this, type)}
                      {...options}
                    />
                  );
                })}

                {Object.keys(schema.filters3 || {}).map((field) => {
                  let { type, ...options } = schema.filters3[field];

                  return (
                    <InputFactory
                      key={field}
                      className="ms-1 "
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter3.bind(this, type)}
                      {...options}
                    />
                  );
                })}

                <DateRange
                  onChange={this.onChangeDate.bind(this)}
                  field="createdAt"
                />

                <div className="dropdown">
                  <button
                    className="border-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-three-dots"></i>
                  </button>
                  <ul className="dropdown-menu">
                    {(user[0].id === "admin" ||
                      user[0].id === "masterAdmin") && (
                      <li
                        className="dropdown-item"
                        role="button"
                        onClick={this.onClickExport.bind(this)}
                      >
                        <i className="bi bi-filetype-csv"></i> Export CSV
                      </li>
                    )}
                    {(user[0].id === "admin" || user[0].id === "masterAdmin") &&
                      selected.length > 0 && (
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={this.onClickDeleteSelected.bind(this)}
                        >
                          <i className="bi bi-trash"></i> Delete Selected
                        </li>
                      )}
                  </ul>
                </div>
              </div>

              <PoTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "remark",
                    "chart_accounts",
                    "tin",
                    "particulars",
                    "supplier",
                    "requestNumber",
                    "items_list",
                    "client_name",
                    "items",
                    "due_date",
                    "purchaseOrderNumber",
                    "remarkss",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#EBBD2F",
                }}
              >
                <i className="bi bi-plus" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default ExpensePage;

import { dialog } from "nq-component";
import BaseFormPresenter from "../../../base/BaseFormPresenter";
import classNames from "../../../classNames";
import { findObjectUseCase } from "../../../usecases/object";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};
class ProjectViewPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }
  componentDidMount() {
    this.init();
    this.getObject();
    this.getSumCollections();
  }

  async getSumCollections() {
    const query = {
      where: {
        project_name: { id: this.view.props.params.id },
        client_name: { id: this.view.props.params.cid },
      },
    };
    const query2 = {
      where: {
        client_name: { id: this.view.props.params.cid },
        project_name: { id: this.view.props.params.id },
      },
    };
    const expense = await findObjectUseCase().execute("expense", query);
    const invoice = await findObjectUseCase().execute("invoices_final", query2);
    const estimate = await findObjectUseCase().execute(
      "services_final",
      query2
    );
    console.log("IIIINNNVVV", invoice);
    const totalExpense = expense.reduce((acc, curr) => acc + curr.amount, 0);
    // const totalInvoice = invoice.reduce((acc, curr) => acc + curr.total, 0);
    const totalInvoice = invoice.reduce(
      (acc, curr) => acc + curr.downpaymentVal,
      0
    );
    const totalReceived = invoice.reduce((acc, curr) => acc + curr.received, 0);
    // const totalIncome = invoice
    //   .filter((item) => item.statuses === "Approved")
    //   .reduce((acc, curr) => acc + curr.total, 0);
    // const totalIncome = invoice.reduce((acc, curr) => acc + curr.received, 0);
    // const totalIncome = invoice
    //   .filter((item) => item.statuses === "Approved")
    //   .reduce((acc, curr) => acc + curr.downpaymentVal, 0);
    const totalIncome = invoice
      .filter(
        (item) =>
          item.statuses === "Approved" ||
          item.statuses === "Partially Paid" ||
          item.statuses === "Paid"
      )
      .reduce((acc, curr) => acc + curr.downpaymentVal, 0);

    const totalEstimate = estimate.reduce((acc, curr) => acc + curr.total, 0);

    console.log("summertime", totalExpense);
    console.log("summerday", totalInvoice);
    console.log("summerweek", totalEstimate);
    console.log("summerweekt", totalIncome);
    const tax = totalIncome * 0.12;
    console.log("tatatatatattaa", tax);
    this.view.setState({
      expenses: totalExpense,
      invoices: totalInvoice,
      // incomes: totalIncome - tax,
      incomes: totalIncome - invoice[0]?.tax,
      // incomes: totalIncome,
      estimates: totalEstimate,
      received: totalReceived,
    });
  }
  async submit() {
    console.log("Dates", this.object.startDate, this.object.endDate);
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    if (!this.change.statuses) {
      this.change.statuses = "In Progress";
    }
    if (!this.change.time || this.object.time) {
      if (
        !this.change.startDate &&
        !this.change.endDate &&
        !this.object.startDate &&
        !this.object.endDate
      ) {
        this.change.time = "No dates";
      } else {
        let startDate = this.object.startDate
          ? this.object.startDate
          : this.change.startDate;
        let endDate = this.object.endDate
          ? this.object.endDate
          : this.change.endDate;
        this.change.time = `${formatDate(startDate)} - ${formatDate(endDate)}`;
        console.log("time", this.change.time);
      }
    }

    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Data has been successfully saved.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/project");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  async onClickStatus(status) {
    const collection = "projects";
    this.object.statuses = status;

    try {
      const data = await this.upsertUseCase.execute(collection, this.object);
      if (data.statuses === this.object.statuses) {
        this.view.setObject(this.object);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }
  onClickExport() {
    this.view.exportPDF();
  }
}

export default ProjectViewPresenter;

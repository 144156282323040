import React from "react";
import InputFactory from "../../../../../components/InputFactory";

export default function SelectAccountsModal({ object, onChange, schema }) {
  return (
    <div className="col">
      <div className="row">
        <p
          className="fw-semibold"
          style={{ fontSize: "1.3rem", color: "#DAB44E" }}
        >
          Mark as Paid
        </p>

        <div className="mb-3 col-8">
          <div className="row mt-4">
            <label
              class="form-label fw-semibold"
              style={{ fontSize: "0.875rem" }}
            >
              Projects
              <span className="text-danger ms-1">*</span>
            </label>
            <InputFactory
              object={object}
              field="project_name"
              type="Pointer"
              col="row-6"
              required
              target="projects"
              schema={schema}
              label="Project"
              onChange={onChange}
              placeholder="Select Project"
            />
          </div>
          <label
            class="form-label fw-semibold mt-4"
            style={{ fontSize: "0.875rem" }}
          >
            Bank
            <span className="text-danger ms-1">*</span>
          </label>
          <div className="row">
            <InputFactory
              object={object}
              field="bank"
              required
              type="Pointer"
              target="gmd_accounts"
              schema={schema}
              label="Bank"
              where={{
                $or: [
                  { name: "BDO Main" },
                  { name: "BPI" },
                  { name: "Metrobank" },
                ],
              }}
              onChange={onChange}
              placeholder="Select Bank"
            />
          </div>
          <div className="row mt-4 ">
            <label
              class="form-label fw-semibold"
              style={{ fontSize: "0.875rem" }}
            >
              Chart of Accounts
              <span className="text-danger ms-1">*</span>
            </label>
            <InputFactory
              object={object}
              field="chartOfAccounts"
              type="Pointer"
              col="row-6"
              required
              target="chart_of_accounts"
              schema={schema}
              label="Chart of Accounts"
              onChange={onChange}
              placeholder="Select Account"
            />
          </div>
          <div className="row mt-4 ">
            <label
              class="form-label fw-semibold"
              style={{ fontSize: "0.875rem" }}
            >
              Description
              <span className="text-danger ms-1">*</span>
            </label>
            <InputFactory
              className="ms-2"
              object={object}
              field="description"
              type="String"
              col="row-6"
              required
              schema={schema}
              label="Description"
              onChange={onChange}
              placeholder="Enter Description"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import logo from "../../../assets/gmd-logo.png";
import dateFormat from "../../../dateFormat";
import { findObjectUseCase } from "../../../usecases/object";

const getDiscountOrASF = (value, type, subtotal) => {
  if (!value) return 0;

  if (type === "value") return value;
  console.log(value, type, subtotal);
  console.log(subtotal * (value / 100));

  return subtotal * (value / 100);
};

export default function InvoicesPrint({ fetchedObject, object, services }) {
  // const [fetchedObject, setFetchedObject] = React.useState(null);
  // const mamamo = object[0]?.id;
  // React.useEffect(() => {
  //   async function fetchData() {
  //     const query = {
  //       include: [
  //         "All",
  //         "client_name",
  //         "services",
  //         "services.questions",
  //         "services.questions.service",
  //         "project_name",
  //       ],
  //     };
  //     try {
  //       const fetchedData = await findObjectUseCase().execute(
  //         "invoices_final",
  //         query
  //       );
  //       const fetchedObjects = fetchedData.find((item) => item.id === mamamo);
  //       console.log("lolololo", fetchedObjects);

  //       setFetchedObject(fetchedObjects);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }

  //   fetchData();
  // }, [mamamo]);
  // if (!fetchedObject) {
  //   return <div>Loading...</div>;
  // }

  console.log("asas", fetchedObject);

  object?.forEach((invoice) => {
    services.forEach((service) => {
      if (service.invoice_num === invoice.invoice_num) {
        const existingService = invoice.services.find(
          (existingService) => existingService.id === service.id
        );
        if (!existingService) {
          invoice.services.push(service);
        }
      }
    });
  });

  const paper = {
    padding: "20mm",
    fontFamily: "Montserrat, sans-serif",
  };

  const logoStyle = {
    width: "30mm",
  };

  const header = {
    display: "flex",
    justifyContent: "space-between",
  };

  const styles = {
    table: {
      borderCollapse: "collapse",
      width: "100%",
      marginBottom: "10px",
      tableLayout: "fixed",
    },
    tableDesc: {
      borderCollapse: "collapse",
      marginBottom: "10px",
      width: "100%",
    },
    header: {
      backgroundColor: "#FFE8CC",
      color: "#FFAE4D",
      padding: "10px 0",
      fontSize: "0.6rem",
      width: "15%",
    },
    activityHeader: {
      textAlign: "left",
      padding: "10px",
      backgroundColor: "#FFE8CC",
      color: "#FFAE4D",
      fontSize: "0.6rem",
      width: "50%",
    },
    dataCellFirst: {
      padding: "10px",
      verticalAlign: "top",
    },
    dataCellName: {
      padding: "10px",
      verticalAlign: "top",
      textAlign: "left",
    },
    dataCell: {
      padding: "10px",
      verticalAlign: "top",
      textAlign: "center",
    },
    headerTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
    },
    dataCellTotal: {
      backgroundColor: "#2e2e2e",
      color: "white",
      padding: "10px",
      verticalAlign: "top",
    },
    itemTable: {
      borderCollapse: "collapse",
      width: "100%",
      marginBottom: "10px",
    },
    summaryTable: {
      borderCollapse: "collapse",
      width: "100%",
    },
    headerCell: {
      backgroundColor: "#FFA500",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    itemCell: {
      padding: "10px",
      border: "1px solid #000",
      borderTop: "none",
    },
    summaryHeaderCell: {
      backgroundColor: "#000",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    summaryCell: {
      padding: "10px",
      border: "1px solid #000",
      backgroundColor: "#f2f2f2",
    },
  };

  const line = {
    border: "none",
  };

  const containerStyles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      margin: 0,
      padding: 0,
    },
    oneContainer: {
      flexBasis: "50%",
      fontSize: "16px",
      textAlign: "left",
    },
    subContainer: {
      flexBasis: "50%",
      fontSize: "16px",
      textAlign: "center",
    },
    subTable: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "10px",
    },
    subTableCell: {
      padding: "5px 10px",
      border: "1px solid #000",
    },
    subTableHeader: {
      backgroundColor: "#FFA500",
      color: "white",
      padding: "10px",
      border: "1px solid #000",
    },
    phContainer: {
      flexBasis: "25%",
      fontSize: "25px",
      fontWeight: "bold",
      textAlign: "end",
    },
  };

  // Calculating subtotal, tax, and total
  const subtotal = object.items
    ?.map((items) => Number(items.amounts?.replace(/,/g, "")) * items.quantity)
    .reduce((acc, prev) => acc + prev, 0);

  const taxRate = 0.0;
  const tax = subtotal * taxRate;
  const shippingCost = 0;
  const total = subtotal + tax + shippingCost;
  const vendor = object[0]?.vendor_name;

  console.log("OBJECT", object);

  // Grouping services by question name
  const groupedServices = {};
  object[0]?.services?.forEach((serviceItem) => {
    serviceItem.questions.forEach((question) => {
      if (!groupedServices[question.name]) {
        groupedServices[question.name] = [];
      }
      groupedServices[question.name].push(question);
    });
  });

  const groupByName = (services) => {
    const grouped = {};

    services?.forEach((service, sindex) => {
      service.questions.forEach((question) => {
        const name = question.name;
        if (!grouped[name]) {
          grouped[name] = [];
        }
        grouped[name].push({
          ...question,
          serviceIndex: sindex,
        });
      });
    });

    return grouped;
  };

  const groupedQuestions = groupByName(fetchedObject?.services);
  const formatToPhilippinePeso = (value) => {
    return new Intl.NumberFormat("en-PH", {
      style: "currency",
      currency: "PHP",
    }).format(value);
  };

  return (
    <div style={paper} className="">
      <div style={header}>
        <div>
          <p style={{ margin: "0" }}>
            <b>GMD PRODUCTIONS INC</b>
          </p>
          <p style={{ margin: "0" }}>32 Santan Unit H Brgy Fortune Marikina</p>
          <p style={{ margin: "0" }}>Marikina, NCR 1802 PH</p>
          <p style={{ margin: "0" }}>+63 9688870928</p>
          <p style={{ margin: "0" }}>pjg@gmd.ph</p>
          <p style={{ margin: "0" }}>https://www.gmd.ph</p>
        </div>
        <div style={{ textAlign: "end" }}>
          <p>
            <img src={logo} alt="GMD Logo" style={logoStyle} />
          </p>
        </div>
      </div>
      <div style={{ color: "#FFAE4D", fontSize: "26px", marginTop: "25px" }}>
        INVOICE
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flexGrow: 1 }}>
          <p
            style={{ fontSize: "14px", marginTop: "13px", marginBottom: "3px" }}
          >
            <b>BILL TO</b>
          </p>
          <p
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "13px",
              margin: 0,
            }}
          >
            {fetchedObject?.client_name?.name}
          </p>
          <p
            style={{
              fontFamily: "Montserrat, sans-serif",
              fontSize: "13px",
              margin: 0,
            }}
          >
            {fetchedObject?.client_name?.name}
          </p>
        </div>
        <div
          style={{ display: "flex", marginRight: "10px", marginTop: "10px" }}
        >
          <div style={{ flex: 1, textAlign: "end", paddingRight: "5px" }}>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
                whiteSpace: "nowrap",
              }}
            >
              <b>INVOICE NO.</b>
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
                whiteSpace: "nowrap",
              }}
            >
              <b>DATE</b>
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
                whiteSpace: "nowrap",
              }}
            >
              <b>DUE DATE</b>
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
                whiteSpace: "nowrap",
              }}
            >
              <b>TERMS</b>
            </p>
          </div>
          <div style={{ flex: 1, textAlign: "start" }}>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
              }}
            >
              {fetchedObject?.invoice_num}
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
              }}
            >
              {fetchedObject?.due_date}
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
              }}
            >
              {fetchedObject?.due_date}
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "13px",
                margin: 0,
                whiteSpace: "nowrap",
              }}
            >
              Due on receipt
            </p>
          </div>
        </div>
      </div>
      <hr style={{ borderColor: "#FFAE4D", marginBottom: "20px" }} />
      <div>
        <table style={styles.table}>
          {Object.keys(groupedQuestions).map((name) => (
            <table
              key={name}
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginBottom: "20px",
                border: "1px dotted black",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "yellow" }}>
                  <th
                    colSpan={5}
                    style={{
                      border: "1px dotted black",
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    {name}
                  </th>
                </tr>
                <tr style={{ backgroundColor: "lightgray" }}>
                  <th
                    style={{
                      border: "1px dotted black",
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    Description
                  </th>
                  <th
                    style={{
                      border: "1px dotted black",
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      border: "1px dotted black",
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    Rate
                  </th>
                  <th
                    style={{
                      border: "1px dotted black",
                      textAlign: "left",
                      padding: "10px",
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "#f0ecec" }}>
                {groupedQuestions[name].map((question, index) => (
                  <tr key={index} style={{ border: "1px dotted black" }}>
                    {/* <td
                      style={{
                        border: "1px dotted black",
                        padding: "10px",
                        maxWidth: "200px", // Adjust the max-width as needed
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: "13px",
                        }}
                      >
                        {fetchedObject?.descriptionArray &&
                        fetchedObject?.descriptionArray.find(
                          (desc) => desc.categoryIndex === question.serviceIndex
                        )
                          ? fetchedObject?.descriptionArray.find(
                              (desc) =>
                                desc.categoryIndex === question.serviceIndex
                            )?.description
                          : null}
                      </div>
                    </td> */}
                    <td
                      style={{
                        border: "1px dotted black",
                        padding: "10px",
                        maxWidth: "200px", // Adjust the max-width as needed
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: "13px",
                        }}
                      >
                        {fetchedObject?.descriptionArray &&
                        fetchedObject?.descriptionArray.find(
                          (desc) => desc.categoryIndex === question.serviceIndex
                        )
                          ? fetchedObject?.descriptionArray
                              .find(
                                (desc) =>
                                  desc.categoryIndex === question.serviceIndex
                              )
                              ?.description.split("\n")
                              .map((line, index) => (
                                <span key={index}>
                                  {line}
                                  <br />
                                </span>
                              ))
                          : null}
                      </div>
                    </td>

                    <td
                      style={{
                        border: "1px dotted black",
                        padding: "10px",
                      }}
                    >
                      {question?.quantity}
                    </td>
                    <td
                      style={{
                        border: "1px dotted black",
                        padding: "10px",
                      }}
                    >
                      {formatToPhilippinePeso(question?.rate)}
                    </td>
                    <td
                      style={{
                        border: "1px dotted black",
                        padding: "10px",
                      }}
                    >
                      {formatToPhilippinePeso(question?.amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </table>
      </div>
      <hr style={{ borderColor: "gray", marginTop: "10px" }} />
      <div style={containerStyles.container}>
        <div style={containerStyles.oneContainer}>
          <p>
            Thank you for your business! We hope that we can continue to help
            grow your company even further.
          </p>
          <p style={{ margin: 0 }}>Account Name: GMD PRODUCTIONS INC</p>
          <p style={{ margin: 0 }}>BDO CHECKING - 012758000370</p>
          <p style={{ margin: 0 }}>BPI CHECKING - 6011 04 82 03</p>
        </div>
        <div style={containerStyles.subContainer}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tr>
              {/* <th style={{ padding: "10px", textAlign: "right" }}>Subtotal</th> */}
              <th style={{ padding: "10px", textAlign: "left" }}>Subtotal:</th>
              <td style={{ padding: "10px", textAlign: "right" }}>
                {/* ₱ {Number(object[0]?.toFixed(2).subTotal).toLocaleString()} */}
                {formatToPhilippinePeso(Number(object[0]?.subTotal).toFixed(2))}
              </td>
            </tr>
            {object[0]?.discount ? (
              <tr>
                <th style={{ padding: "10px", textAlign: "left" }}>
                  Discount:
                </th>
                <td style={{ padding: "10px", textAlign: "right" }}>
                  {`
                 ${formatToPhilippinePeso(
                   getDiscountOrASF(
                     object[0]?.discount,
                     object[0]?.discountType,
                     object[0]?.subTotal
                   ).toFixed(2)
                 )}`}
                </td>
              </tr>
            ) : null}
            {object[0]?.asf ? (
              <tr>
                <th style={{ padding: "10px", textAlign: "left" }}>ASF:</th>
                <td style={{ padding: "10px", textAlign: "right" }}>
                  {`
                 ${formatToPhilippinePeso(
                   getDiscountOrASF(
                     object[0]?.asf,
                     object[0]?.asfType,
                     object[0]?.subTotal
                   ).toFixed(2)
                 )}`}
                </td>
              </tr>
            ) : null}
            {/* <tr>
              <th style={{ padding: "10px", textAlign: "right" }}>VAT</th>
              <td style={{ padding: "10px", textAlign: "center" }}>
                ₱ {object[0]?.tax?.toLocaleString()}
              </td>
            </tr> */}
            {/* {object[0]?.tax?.toFixed(2).toLocaleString() !== "0" && (
              <tr>
                <th style={{ padding: "10px", textAlign: "left" }}>TAX:</th>
                <td style={{ padding: "10px", textAlign: "right" }}>
                  {formatToPhilippinePeso(
                    object[0]?.tax?.toFixed(2).toLocaleString()
                  )}
                </td>
              </tr>
            )} */}
            {object[0]?.addVAT === true && (
              <tr>
                <th style={{ padding: "10px", textAlign: "left" }}>TAX:</th>
                <td style={{ padding: "10px", textAlign: "right" }}>
                  {formatToPhilippinePeso(
                    object[0]?.tax?.toFixed(2).toLocaleString()
                  )}
                </td>
              </tr>
            )}
            {/* {object[0]?.downpaymentVal ? (
              <tr>
                <th style={{ padding: "10px", textAlign: "right" }}>Deposit</th>
                <td style={{ padding: "10px", textAlign: "center" }}>
                  ₱ {object[0]?.downpaymentVal?.toFixed(2).toLocaleString()}
                </td>
              </tr>
            ) : null} */}
            {object[0]?.downpaymentVal
              ? // <tr>
                //   {/* <th style={{ padding: "10px", textAlign: "right" }}>Deposit</th> */}
                //   <th style={{ padding: "10px", textAlign: "right" }}>
                //     {/* Invoice Amount */}
                //   </th>
                //   <td style={{ padding: "10px", textAlign: "center" }}>
                //     {object[0]?.downpaymentType === "value" && (
                //       <>
                //         ₱ {object[0]?.downpaymentVal?.toFixed(2).toLocaleString()}
                //       </>
                //     )}
                //     {object[0]?.downpaymentType === "percent" && (
                //       <>{object[0]?.downpaymentAmount?.toFixed(2)}%</>
                //     )}
                //   </td>
                // </tr>
                null
              : null}
            {/* {object[0]?.received ? (
              <tr>
                <th style={{ padding: "10px", textAlign: "right" }}>
                  RECEIVED
                </th>
                <td style={{ padding: "10px", textAlign: "center" }}>
                  ₱ {object[0]?.received?.toLocaleString()}
                </td>
              </tr>
            ) : null} */}
            <tr>
              {/* <th style={{ padding: "10px", textAlign: "right" }}>TOTAL</th> */}
              <th style={{ padding: "10px", textAlign: "left" }}>TOTAL:</th>
              <td style={{ padding: "10px", textAlign: "right" }}>
                {/* ₱ {object[0]?.total?.toLocaleString()} */}
                {formatToPhilippinePeso(
                  object[0]?.total.toFixed(2).toLocaleString()
                )}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  padding: "10px",
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {/* BALANCE DUE */}
                {/* PAYMENT */}
                FOR PAYMENT:
              </th>
              <td
                style={{
                  padding: "10px",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  backgroundColor: "#FFE8CC",
                }}
              >
                {/* {object[0]?.received
                  ? ` ${(
                      Number(object[0]?.total) - Number(object[0]?.received)
                    ).toLocaleString()}`
                  : `${Number(object[0]?.total)}`} */}
                {/* {object[0]?.received
                  ? formatToPhilippinePeso(
                      Number(object[0]?.total) -
                        Number(object[0]?.received) -
                        Number(object[0]?.downpaymentVal)
                    )
                  : formatToPhilippinePeso(
                      Number(object[0]?.total) -
                        Number(object[0]?.downpaymentVal)
                    )} */}
                {/* {object[0]?.received
                  ? formatToPhilippinePeso(
                      Number(object[0]?.total) -
                        Number(object[0]?.received) -
                        Number(object[0]?.downpaymentVal)
                    )
                  : formatToPhilippinePeso(
                      Number(object[0]?.total) -
                        Number(object[0]?.downpaymentVal)
                    )} */}
                {object[0]?.received
                  ? formatToPhilippinePeso(
                      Number(object[0]?.downpaymentVal)
                      // -
                      //   Number(object[0]?.received)
                    )
                  : formatToPhilippinePeso(Number(object[0]?.downpaymentVal))}
              </td>
            </tr>
            {object[0]?.received ? (
              <tr>
                <th style={{ padding: "10px", textAlign: "left" }}>
                  RECEIVED:
                </th>
                <td style={{ padding: "10px", textAlign: "right" }}>
                  {formatToPhilippinePeso(
                    object[0]?.received?.toFixed(2).toLocaleString()
                  )}
                </td>
              </tr>
            ) : null}
            {object[0]?.statuses === "Paid" ? null : (
              <>
                {object[0]?.downpaymentAmount === 100 &&
                object[0]?.downpaymentType === "percent" ? null : (
                  <></>
                  // <tr>
                  //   <th style={{ padding: "10px", textAlign: "left" }}>
                  //     BALANCE DUE AFTER PAYMENT:
                  //   </th>
                  //   <td
                  //     style={{
                  //       padding: "10px",
                  //       textAlign: "center",
                  //       borderBottom: "2x solid black",
                  //       fontWeight: "bold",
                  //       textAlign: "right",
                  //     }}
                  //   >

                  //     {object[0]?.collectibles &&
                  //       formatToPhilippinePeso(
                  //         Number(object[0]?.downpaymentVal) -
                  //           Number(object[0]?.received)
                  //       )}
                  //   </td>
                  // </tr>
                )}
              </>
            )}
          </table>
        </div>
      </div>
      {/* <div className="d-flex">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            borderBottom: "2px solid black",
            marginBottom: "10px",
          }}
        >
          <span
            className="me-1"
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "left",
              padding: "5px 10px",
            }}
          >
            RUNNING BALANCE
          </span>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "right",
              padding: "5px 10px",
            }}
          >
            
            {object[0]?.collectibles &&
              formatToPhilippinePeso(
                Number(object[0]?.downpaymentVal) - Number(object[0]?.received)
              )}
          </span>
        </div>
      </div> */}
    </div>
  );
}

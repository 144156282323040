const ItemInput = ({ index, item, chartOptions, onChange, onRemove }) => {
  const formatNumber = (value) => {
    if (!value) return "";
    const [integerPart, decimalPart] = value.split(".");
    const formattedInteger = parseInt(
      integerPart.replace(/,/g, "") || "0",
      10
    ).toLocaleString("en-US");
    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;

    const cleanedValue = inputValue
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\./g, "$1");

    onChange(index, "amounts", cleanedValue);

    e.target.value = formatNumber(cleanedValue);
  };

  return (
    <div className="row g-3 align-items-end mb-3">
      <div className="col">
        <label className="form-label">Name</label>
        <input
          type="text"
          className="form-control"
          value={item.name}
          onChange={(e) => onChange(index, "name", e.target.value)}
          placeholder="Item Name"
          required
        />
      </div>
      <div className="col">
        <label className="form-label">Amount</label>
        <input
          type="text"
          className="form-control"
          value={formatNumber(item.amounts)}
          defaultValue={formatNumber(item.amounts.toString())}
          onInput={handleAmountChange}
          placeholder="Amount"
          required
        />
      </div>
      <div className="col">
        <label className="form-label">Quantity</label>
        <input
          type="number"
          className="form-control"
          value={item.quantity}
          onChange={(e) =>
            onChange(
              index,
              "quantity",
              e.target.value ? parseInt(e.target.value) : 1
            )
          }
          placeholder="Quantity"
          min="1"
          required
        />
      </div>
      <div className="col">
        <label className="form-label">Chart of Accounts</label>
        <select
          className="form-control"
          value={item.chart_of_accounts?.id || ""}
          onChange={(e) => {
            const selectedId = e.target.value;
            const selectedAccount = chartOptions.find(
              (option) => option.id === selectedId
            );
            onChange(index, "chart_of_accounts", selectedAccount || null);
          }}
          required
        >
          <option value="">Select Account</option>
          {chartOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>

      <div className="col-auto">
        {index === 0 ? null : (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => onRemove(index)}
          >
            Remove
          </button>
        )}
      </div>
    </div>
  );
};

export default ItemInput;

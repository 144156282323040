import React from "react";
import { InputJson, Button, dialog } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
  deleteObjectUseCase,
} from "../../../usecases/object";
import withRouter from "../../../withRouter";
import BaseFormPage from "../../../base/BaseFormPage";
import NavBar from "../../../components/navbar";
import { restUseCase } from "../../../usecases/rest";
import ProjectViewPresenter from "./ProjectViewPresenter";
import InputFactory from "../../../components/InputFactory";
import Drop from "../../../components/DropdownFilter";
import ProjectTaskCard from "./projectTaskCard/ProjectTaskCard";
import ProjectTab from "./projectTabs/ProjectTab";
import ProjectNav from "./projectNav/ProjectNav";
import MarkDoneDialogContent from "../components/MarkDoneDialogContent";
import classNames from "../../../classNames";
import DeleteProjectDialogContent from "../components/DeleteProjectDialogContent";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class ProjectViewPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
      expenses: [],
      invoices: [],
      estimates: [],
      expenses: [],
      incomes: [],
      received: [],
    };
    this.presenter = new ProjectViewPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase(),
      deleteObjectUseCase()
    );
    this.contractPDF = React.createRef();
  }

  getCollectionName() {
    return "projects";
  }

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }
  componentDidMount() {
    this.presenter.componentDidMount();
    // this.presenter.getSumCollections();
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  onClickDone() {
    dialog.fire({
      html: (
        <MarkDoneDialogContent
          onDone={() => {
            this.presenter.onClickStatus("Done");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }
  onClickDelete() {
    const collection = this.getCollectionName();
    const object = this.state.object;

    dialog.fire({
      html: (
        <DeleteProjectDialogContent
          object={object}
          onDelete={() => {
            console.log("delete", collection, object.id);
            deleteObjectUseCase().execute(collection, object.id);
            dialog.close();
            this.navigateTo("/project");
            dialog.fire({
              html: (
                <div className="text-center">
                  <i
                    className={classNames(
                      "bi bi-file-earmark-check",
                      "text-danger"
                    )}
                    style={{ fontSize: "5rem" }}
                  ></i>
                  <h4 className="fw-bold">Project Deleted</h4>
                  <p className="m-0">
                    The project has been deleted successfully.
                  </p>
                  <button
                    className="btn btn-primary mb-2 mt-2"
                    onClick={() => dialog.close()}
                  >
                    Confirm
                  </button>
                </div>
              ),
              footer: false,
            });
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }
  onClickEditTimeline() {
    const object = this.state.object;
    this.navigateTo("/view/project/timeline/" + object.id);
  }
  onClickPrintCOC() {
    const object = this.state.object;
    this.navigateTo("/view/project/print/" + object.id);
  }
  onClickViewTask(task) {
    const projectName = this.state.object.name;
    const projectId = this.state.object.id;
    const typeName = task;
    console.log("task", projectId, typeName);
    const queryParams = new URLSearchParams();
    queryParams.set("name", projectName);
    queryParams.set("id", projectId);
    queryParams.set("type", typeName);
    this.navigateTo(`/view/project/tasks/?${queryParams.toString()}`);
  }
  onClickBackProj() {
    this.navigateTo(`/project`);
  }

  render() {
    // Testing
    const { expenses, invoices, incomes, estimates, received } = this.state;
    let { projectManpower, projectMaterial } = "";
    const projectEstimate = estimates;
    const projectInvoice = invoices;
    const projectIncome = incomes;
    console.log("CORRECT INCOME", projectIncome);
    const projectCost = expenses;
    const projectReceived = received;
    const projectProfit = projectIncome - projectCost;
    // const projectProfitMargin = (projectProfit / projectIncome) * 100;
    const projectProfitMargin = Math.max(
      (projectProfit / projectIncome) * 100,
      0
    );
    const object = this.state.object;
    const currentDate = this.getCurrentDateFormatted();
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    // console.log("summerid", object, object.id, object.client?.id);
    // console.log("project Income", projectIncome, projectInvoice);
    // console.log("edit", this);

    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 px-lg-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="px-lg-2 py-lg-2">
                  <div className="d-flex align-items-center ">
                    <button
                      type="button"
                      className="btn btn-link"
                      style={{ padding: "0" }}
                    >
                      <i
                        className="bi bi-arrow-left fs-4"
                        onClick={this.onClickBackProj.bind(this)}
                        style={{ color: "#DAB451", margin: "0" }}
                      ></i>
                    </button>
                    <h1 className="fw-bold text-capitalize">{object.name}</h1>
                  </div>
                  <p className="fs-5  text-capitalize">
                    <span
                      style={{
                        color:
                          object.statuses === "In Progress"
                            ? "#CF6F0080"
                            : object.statuses === "Done"
                            ? "green"
                            : "",
                      }}
                    >
                      {object.statuses}
                    </span>{" "}
                    <span style={{ color: "#43434380" }}>
                      | Client: {object.client?.name}
                    </span>
                    <span style={{ color: "#43434380" }}>
                      | Category: {object.categories}
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  {object.statuses === "In Progress" && (
                    <Button
                      className="btn m-1 text-white"
                      style={{ backgroundColor: "#4aab73" }}
                      onClick={this.onClickDone.bind(this)}
                    >
                      <i className="bi bi-check" /> Mark as Done
                    </Button>
                  )}
                  {object.statuses === "Done" && (
                    <Button
                      className="btn m-1 text-white"
                      style={{ backgroundColor: "#CF6F00" }}
                      onClick={this.onClickPrintCOC.bind(this)}
                    >
                      <i class="bi bi-printer-fill" /> Print COC (A4)
                    </Button>
                  )}
                  <Button
                    className="btn btn-danger m-1"
                    onClick={this.onClickDelete.bind(this)}
                  >
                    <i className="bi bi-trash" /> Delete
                  </Button>
                </div>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1 px-lg-2  mr-3 ">
                  <div className="d-flex bg-white rounded border">
                    <div className="p-3 w-50 text-center">
                      <p
                        style={{
                          color:
                            projectProfitMargin < 30
                              ? "red"
                              : projectProfitMargin >= 31 &&
                                projectProfitMargin <= 50
                              ? "orange"
                              : "green",
                        }}
                      >
                        {projectProfitMargin
                          ? projectProfitMargin.toFixed(2)
                          : 0}
                        %
                      </p>

                      <p style={{ color: "#43434340" }}>PROFIT MARGIN</p>
                    </div>
                    <div className="p-3">
                      <p style={{ color: "#434343BF" }}>Income</p>

                      <p style={{ color: "#434343BF" }}>Cost</p>
                    </div>
                    <div className="p-3 w-75">
                      <div
                        className="progress mt-2"
                        role="progressbar"
                        aria-label="Income"
                      >
                        <div
                          class="progress-bar bg-success"
                          style={{
                            width: `${(
                              (projectProfit / projectIncome) *
                              100
                            ).toFixed(2)}%`,
                          }}
                        ></div>
                      </div>
                      <br />

                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Cost"
                      >
                        <div
                          className="progress-bar bg-danger"
                          style={{
                            width: `${(
                              (projectCost / projectProfit) *
                              100
                            ).toFixed(2)}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="p-3 w-25">
                      <p style={{ color: "#434343BF" }}>
                        {projectIncome
                          ? "₱" + (projectIncome * 1).toLocaleString()
                          : "₱0.00"}
                      </p>

                      <p style={{ color: "#434343BF" }}>
                        {projectCost
                          ? "₱" + (projectCost * 1).toLocaleString()
                          : "₱0.00"}
                      </p>
                    </div>
                  </div>
                  <div className="flex-grow-1 px-lg-2 py-lg-2 mr-3 ">
                    <ProjectNav
                      object={object}
                      projectIncome={projectIncome}
                      projectCost={projectCost}
                      projectProfit={projectProfit}
                      projectInvoice={projectInvoice}
                      projectManpower={projectManpower}
                      projectEstimate={projectEstimate}
                      projectMaterial={projectMaterial}
                    />
                  </div>
                </div>
                {/* Right Side */}
                <div
                  className="d-flex flex-column"
                  style={{ width: "calc(50% - 2rem)" }}
                >
                  {/* Top Row */}
                  <div className="d-flex mb-3">
                    {/* Left Column */}
                    <div className="flex-grow-1">
                      {/* Top Left */}
                      <div
                        className="d-flex border"
                        style={{
                          backgroundColor: "#F8F8F8",
                          borderTopLeftRadius: "0.375rem",
                          height: "325px",
                        }}
                      >
                        <div className="mr-3 p-3 w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="m-0">DO:</h6>
                            <Button
                              className="btn btn-link p-0 fs-6"
                              onClick={() => this.onClickViewTask("Do")}
                              style={{
                                color: "#D8AE3F",
                              }}
                            >
                              View all
                            </Button>
                          </div>
                          <div style={{ overflowY: "auto", maxHeight: "80%" }}>
                            <ProjectTaskCard
                              key={object.id}
                              projectId={object.id}
                              typeName="Do"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Bottom Left */}
                      <div
                        className="d-flex border"
                        style={{
                          backgroundColor: "#F8F8F8",
                          borderBottomLeftRadius: "0.375rem",
                          height: "325px",
                        }}
                      >
                        <div className="mr-3 p-3 w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="m-0">DELEGATE:</h6>
                            <Button
                              className="btn btn-link p-0 fs-6"
                              onClick={() => this.onClickViewTask("Delegate")}
                              style={{
                                color: "#D8AE3F",
                              }}
                            >
                              View all
                            </Button>
                          </div>
                          <div style={{ overflowY: "auto", maxHeight: "80%" }}>
                            <ProjectTaskCard
                              key={object.id}
                              projectId={object.id}
                              typeName="Delegate"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Right Column */}
                    <div className="flex-grow-1">
                      {/* Top Right */}
                      <div
                        className="d-flex border"
                        style={{
                          backgroundColor: "#F8F8F8",
                          borderTopRightRadius: "0.375rem",
                          height: "325px",
                        }}
                      >
                        <div className="mr-3 p-3 w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="m-0">SCHEDULE:</h6>
                            <Button
                              className="btn btn-link p-0 fs-6"
                              onClick={() => this.onClickViewTask("Schedule")}
                              style={{
                                color: "#D8AE3F",
                              }}
                            >
                              View all
                            </Button>
                          </div>
                          <div style={{ overflowY: "auto", maxHeight: "80%" }}>
                            <ProjectTaskCard
                              key={object.id}
                              projectId={object.id}
                              typeName="Schedule"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Bottom Right */}
                      <div
                        className="d-flex border"
                        style={{
                          backgroundColor: "#F8F8F8",
                          borderBottomRightRadius: "0.375rem",
                          height: "325px",
                        }}
                      >
                        <div className="mr-3 p-3 w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="m-0">DELETE:</h6>
                            <Button
                              className="btn btn-link p-0 fs-6"
                              onClick={() => this.onClickViewTask("Delete")}
                              style={{
                                color: "#D8AE3F",
                              }}
                            >
                              View all
                            </Button>
                          </div>
                          <div style={{ overflowY: "auto", maxHeight: "80%" }}>
                            <ProjectTaskCard
                              key={object.id}
                              projectId={object.id}
                              typeName="Delete"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Bottom Row */}
                  <div className="d-flex justify-content-center">
                    <div className="mr-3 p-3 w-100 bg-white rounded border">
                      <div className="d-flex justify-content-between">
                        <h5>Project Timeline</h5>
                        <Button
                          className="btn btn-link p-0 fs-6"
                          onClick={this.onClickEditTimeline.bind(this)}
                          style={{
                            color: "#D8AE3F",
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                      <div className="">
                        <p className="text-center">{object.time}</p>
                        <p className="text-center">
                          <strong>Installation Date/s: </strong>
                          {object.installDates &&
                          object.installDates.length > 0 ? (
                            object.installDates.map((install, index) => (
                              <p key={index} className="m-0">
                                {formatDate(install.name)}
                              </p>
                            ))
                          ) : (
                            <p>No Date/s</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProjectViewPage);
